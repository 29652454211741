<template>
  <div class="machineItem" @click="goDetails">
    <div class="end_time" v-if="itemType=='list'">
      截止时间: {{ itemData.end_date }}
    </div>
    <div class="work_top_box">
      <div class="inner_left_box">
        <div class="machine_sale_type" v-if="itemType=='list'">
          {{ deal_type_list[itemData.type] }}
        </div>
        <div class="work_name">{{ itemData.name }}</div>
      </div>
    </div>
    <div class="work_center_box">
      <div class="inner_center_left">
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">机械详情:</div>
          <div class="bids_ep_row_text">{{ itemData.category_string }}</div>
        </div>
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">需求类型：</div>
          <div class="bids_ep_row_text">{{ deal_type_list[itemData.type] }}</div>
        </div>
        <div class="bids_ep_row_box" v-if="itemType=='op'">
          <div class="bids_ep_row_title">截止时间：</div>
          <div class="bids_ep_row_text">{{ itemData.end_date }}</div>
        </div>
        <div class="bids_ep_row_box" v-if="itemType=='list'">
          <div class="bids_ep_row_title">地点：</div>
          <div class="bids_ep_row_text">{{ itemData.area }}</div>
        </div>
      </div>
      <div class="bottom_btn_box" v-if="itemType == 'op'">
        <!-- 如果审核不通过 -->
        <div class="refuse_reason" v-if="itemData.status == 'refused'">审核意见：{{ itemData.refuse_reason }}</div>
        <div class="refuse_reason" v-else></div>
        <div class="item_btn_box">
          <button class="q_blue_btn" @click.stop="pauseOrOpenPackage('down')" v-if="itemData.status == 'accept'">下架机械</button>
          <button class="q_blue_btn" @click.stop="pauseOrOpenPackage('accept')" v-if="itemData.status == 'down'">重新上架</button>
          <button class="blue_btn" @click.stop="goDetails">查看详情</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'packageItem',
  props: {
    //list列表 查看   op 我的分包，有底部按钮，可操作
    itemType: {
      type: String,
      default: 'list'
    },
    itemData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      deal_type_list: {
        1: '出租',
        2: '出售',
        3: '求租',
        4: '求售'
      },
    };
  },
  methods: {
    goDetails () {
      this.$router.push({
        path: '/machineRent/details',
        query: {
          id: this.itemData.id,
        }
      })
    },
    pauseOrOpenPackage (type) {
      this.$api.downMechanise({ id: this.itemData.id, status: type }).then(res => {
        if (res.code == 1) {
          if (type == 'accept') {
            this.$message.success('上架成功!');
          } else {
            this.$message.success('下架成功!');
          }
          setTimeout(() => {
            this.$emit('changeData');
          }, 800);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.machineItem {
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #e4e9ff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #e5e5e5;
  padding: 21px 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 0px 12px 0px #c5c8d5;
  }
  .end_time {
    font-size: 13px;
    font-weight: 400;
    color: #999999;
    padding-bottom: 9px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 18px;
  }
  .work_top_box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
    .inner_left_box {
      display: flex;
      align-items: center;
      .machine_sale_type {
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        border: 1px solid #ff5100;
        font-size: 14px;
        font-weight: 500;
        color: #ff5100;
        padding: 1px 5px;
        margin-right: 4px;
      }
      .work_name {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
    }
    .inner_right_classify {
      font-size: 18px;
      color: #3f63ff;
    }
  }

  .work_center_box {
    display: flex;
    justify-content: space-between;
    .bids_ep_row_box {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 8px;
      .bids_ep_row_title {
        color: #999999;
      }
      .bids_ep_row_text {
        color: #333333;
      }
    }
  }

  .bottom_btn_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .refuse_reason {
      font-size: 14px;
      font-weight: 400;
      color: #ff3d3d;
      margin-right: 10px;
    }
    .item_btn_box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: none;
      gap: 0 10px;

      .blue_btn {
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 14px;
      }
      .q_blue_btn {
      }
    }
  }
}
</style>
