<template>
  <div class="productItem">
    <div class="product_image_box">
      <img :src="itemData.images_view[0]" alt="">
    </div>
    <div class="work_center_box">
      <div class="inner_center_left">
        <div class="product_name">{{ itemData.name }}</div>
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">产品类型:</div>
          <div class="bids_ep_row_text">{{ itemData.category_name }}</div>
        </div>
        <div class="product_pirce" v-if="itemData.price_type == 2">面议</div>
        <div class="product_pirce" v-else>{{ itemData.price }}</div>
      </div>
      <div class="bottom_btn_box" v-if="itemType == 'op'">
        <!-- 如果审核不通过 -->
        <div class="item_btn_box">
          <button class="q_blue_btn" @click="pauseOrOpenPackage(0)" v-if="itemData.on_sale == 1">下架产品</button>
          <button class="q_blue_btn" @click="pauseOrOpenPackage(1)" v-if="itemData.on_sale == 0">重新上架</button>
          <button class="blue_btn" @click="goDetails(itemData.id)">查看详情</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'packageItem',
  props: {
    //list列表 查看   op 我的分包，有底部按钮，可操作
    itemType: {
      type: String,
      default: 'list'
    },
    itemData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
    };
  },
  methods: {
    goDetails (id) {
      this.$router.push({
        path: '/proSupply/details',
        query: {
          id
        }
      })
    },
    pauseOrOpenPackage (type) {
      this.$api.downProduct({ id: this.itemData.id, on_sale: type }).then(res => {
        if (res.code == 1) {
          if (type == 'accept') {
            this.$message.success('上架成功!');
          } else {
            this.$message.success('下架成功!');
          }
          setTimeout(() => {
            this.$emit('changeData');
          }, 800);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.productItem {
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #e4e9ff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #e5e5e5;
  padding: 21px 24px;
  display: flex;

  .product_image_box {
    width: 140px;
    height: 140px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #e5e5e5;
    margin-right: 20px;
    flex: none;
    overflow: hidden;
  }
  .product_pirce {
    font-size: 16px;
    font-weight: 400;
    color: #ff5100;
    margin-top: 34px;
  }

  .work_center_box {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding-top: 12px;
    .product_name {
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
    }
    .bids_ep_row_box {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 8px;
      .bids_ep_row_title {
        color: #999999;
      }
      .bids_ep_row_text {
        color: #333333;
      }
    }
  }

  .bottom_btn_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .refuse_reason {
      font-size: 14px;
      font-weight: 400;
      color: #ff3d3d;
      margin-right: 10px;
    }
    .item_btn_box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: none;
      gap: 0 10px;

      .blue_btn {
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 14px;
      }
      .q_blue_btn {
      }
    }
  }
}
</style>
