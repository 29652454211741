<template>
  <div class="PagerBox">
    <el-pagination background layout="prev, pager, next" :total="total" @current-change="changCurrentPage" :page-size="page_size">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    page_size: {
      type: Number,
      default: 10
    }
  },
  methods: {
    changCurrentPage (e) {
      this.$emit('changePage', { page: e, page_size: this.page_size });
      this.$emit('getList', { page: e, page_size: this.page_size });
    },
  }
};
</script>

<style lang="scss">
.PagerBox {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  .el-pagination {
    .is-background {
      .el-pager {
         li {
          background-color: #d8d8d8 !important;
        }
      }
    }
  }
}
</style>
