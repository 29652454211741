<template>
  <div class="jobList">
    <List :numberRow="numberRow" :gutter="gutter">
      <div class="job_item" v-for="(item, index) in lists" :key="index" slot="Item">
        <job :itemType="itemType" :itemData="item"></job>
      </div>
    </List>
  </div>
</template>

<script>
import job from './job.vue';
import List from '../List.vue'
export default {
  name: 'jobList',
  components: { job, List },
  props: {
    numberRow: {
      type: Number,
      default: 2
    },
    gutter: {
      type: Number,
      default: 20
    },
    lists: {
      type: Array,
      default: () => ([])
    },
    itemType: {
      type: String,
      default: 'invitation'
    }
  },
  data () {
    return {};
  },

};
</script>

<style lang="scss">
.jobList {
  .job_item {
  }
}
</style>
