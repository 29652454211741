<template>
  <div class="uploadImageLIst">
    <div class="photos">
      <div class="photo_list">
        <div class="photo_item" v-for="(photo,index) in images" :key="index">
          <IImage :src="photo" />
          <div class="del_photo_btn" @click="delPhoto(index)">
            <img src="@/assets/img/icon/icon_radio_close.png" alt="">
          </div>
        </div>
      </div>
      <div class="photo_upload" v-if="images.length<3">
        <uploadImage stats="images" @success="uploadSuccess">
          <img src="@/assets/img/icon/bg_upload_img.png" alt="" slot="uploadContent">
        </uploadImage>
      </div>
    </div>
  </div>
</template>

<script>
import uploadImage from './uploadImage.vue';
import IImage from '../Image/index.vue'
export default {
  components: {
    uploadImage,
    IImage
  },
  data () {
    return {
      images: []
    }
  },
  methods: {
    uploadSuccess (val) {
      this[val.status].push(val.img);
      this.$emit('success', this.images);
    },
    delPhoto (index) {
      this.images.splice(index, 1);
      this.$emit('success', this.images);
    },
    clearImageList () {
      this.images = [];
    }
  }
}
</script>

<style lang="scss" scoped>
.photos {
  display: flex;
  margin: 19px 0 35px;
  .photo_list {
    display: flex;
    .photo_item {
      width: 140px;
      height: 140px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #e5e5e5;
      margin-right: 22px;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .del_photo_btn {
        position: absolute;
        top: 8px;
        right: 14px;
        cursor: pointer;
        img {
          width: 22px;
          height: 22px;
        }
      }
    }
  }

  .photo_upload {
    img {
      width: 140px;
      height: 140px;
      border-radius: 8px 8px 8px 8px;
    }
  }
}
</style>