<template>
  <div class="publishBids">
    <div class="tabs_box">
      <Tabs :tabData="bidsBar" @handleClick="changeHandle" />
    </div>
    <div class="tips">
      请填写你的企业信息，系统审核完成后将以短信的形式通知到您。
    </div>
    <div class="page_content">
      <div class="form_box">
        <el-form :model="sumitObj" :rules="rules" ref="sumitObj" label-width="100px" class="demo-sumitObj">
          <div class="page_area_title">
            联系人
          </div>
          <el-form-item label="招标单位:" prop="company_name">
            <el-input v-model="sumitObj.company_name" placeholder="请输入招标单位"></el-input>
          </el-form-item>
          <el-form-item label="联系人:" prop="contact">
            <el-input v-model="sumitObj.contact" placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="联系方式:" prop="contact_phone">
            <el-input v-model="sumitObj.contact_phone" placeholder="请输入联系方式"></el-input>
          </el-form-item>
          <div class="page_area_title">
            {{curTypeText[curBidsType]}}信息
          </div>
          <el-form-item label="项目名称:" prop="project_name">
            <el-input v-model="sumitObj.project_name" placeholder="请输入项目名称"></el-input>
          </el-form-item>
          <el-form-item label="公告类型:" prop="category_id">
            <el-select v-model="sumitObj.category_id" placeholder="请选择公告类型">
              <el-option :label="item.name" :value="item.id" v-for="(item,index) in $store.state.categoryConfig.invitation_notice"
                :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目地区:" prop="area_code">
            <RegionSelect :value="sumitObj.area_code" @changeRegion="changeRegion"></RegionSelect>
          </el-form-item>
          <el-form-item label="截止时间:" prop="end_date">
            <el-date-picker :picker-options="pickerOptions" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="sumitObj.end_date" type="date"
              placeholder="请选择招标截止日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="项目金额(元):" prop="money">
            <el-input v-model="sumitObj.money" placeholder="请输入真实项目金额" type="number"></el-input>
          </el-form-item>
          <el-form-item label="中标单位:" prop="win_company" v-if="curBidsType=='win'">
            <el-input v-model="sumitObj.win_company" placeholder="请输入中标单位"></el-input>
          </el-form-item>
          <div class="page_area_title">
            <span class="must_point">*</span> {{curTypeText[curBidsType]}}文件
          </div>
          <div class="upload_img_box">

            <div class="upload_img_tags_text">
              您可将您的{{curTypeText[curBidsType]}}文件拍照上传
            </div>
            <el-form-item prop="images">
              <uploadImageList ref="uploadImageList" @success="uploadSuccess">
              </uploadImageList>
            </el-form-item>
          </div>
          <div class="page_area_title">
            <span class="must_point">*</span> {{curTypeText[curBidsType]}}详情
          </div>
          <div class="text_area_box">
            <el-form-item prop="desc">
              <el-input type="textarea" v-model="sumitObj.desc" placeholder="可输入项目名称、介绍、项目编号、地址、项目类型等信息"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <button class="blue_btn" @click="submitForm('sumitObj')">发布{{curTypeText[curBidsType]}}</button>
    </div>
  </div>
</template>
  
<script>
import Tabs from '@/components/Tabs.vue'
import RegionSelect from '../../../components/regionSelect.vue'
import uploadImageList from '@/components/uploadImage/uploadImageList.vue';
import { validatePass } from '../../../utils';
export default {
  components: {
    Tabs,
    RegionSelect,
    uploadImageList
  },
  data () {
    return {
      pageAuth: 'publish_invitation',
      curBidsType: 'invitation',
      curTypeText: {
        invitation: '招标',
        win: '中标',
      },
      sumitObj: {
        company_name: '',
        contact: '',
        contact_phone: '',
        project_name: '',
        category_id: '',
        end_date: '',
        money: '',
        area: '',
        area_code: [],
        desc: '',
        images: [],
      },
      pickerOptions: {
        disabledDate (date) {
          //这里设置今天以前的不可选
          return date.getTime() < Date.now() - 60 * 60 * 1000;
        },
      },
      rules: {
        company_name: [
          { required: true, message: '请输入招标单位', trigger: 'blur' },
        ],
        contact: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        contact_phone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
        project_name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
        ],
        category_id: [
          { required: true, message: '请选择公告类型', trigger: 'change' }
        ],
        area_code: [
          { required: true, message: '请选择项目地区', trigger: 'change' }
        ],
        end_date: [
          { required: false, message: '请选择截止日期', trigger: 'change' }
        ],
        money: [
          { required: false, message: '请输入真是项目金额', trigger: 'blur' },
        ],
        images: [
          { required: true, message: '请选择上传招标文件', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请输入招标详情', trigger: 'blur' },
        ],
      },
      bidsBar: [
        {
          id: 1,
          text: '发布招标',
          value: 'invitation',
          active: true
        },
        {
          id: 2,
          text: '发布中标',
          value: 'win',
          active: false
        },
      ],
    }
  },
  methods: {
    changeHandle (index) {
      this.bidsBar.forEach((item) => {
        item.active = false
      })
      this.bidsBar[index].active = true
      this.curBidsType = this.bidsBar[index].value;
    },
    uploadSuccess (val) {
      this.$set(this.sumitObj, 'images', val);
    },
    changeRegion (val) {
      console.log(val, 'sasa');
      this.sumitObj.area_code = val.area_code;
      this.sumitObj.area = val.area;
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            ...this.sumitObj
          }
          params.area_code = `${this.sumitObj.area_code[0]}/${this.sumitObj.area_code[1]}`;
          params.images += '';
          if (this.curBidsType == 'invitation') delete params.win_company;
          this.$api.publishBids(this.curBidsType, params).then(res => {
            console.log(res, '发布结果');
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.sumitObj = {
                company_name: '',
                contact: '',
                contact_phone: '',
                project_name: '',
                category_id: '',
                end_date: '',
                money: '',
                area: '',
                area_code: [],
                desc: '',
                images: [],
                win_company: ''
              }
              this.$refs.uploadImageList.clearImageList();
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  },
  mounted () {
    this.checkMemberDealAuth(this.pageAuth);
  },
  watch: {

  },
}
</script>
  
<style lang='scss' scoped>
.publishBids {
  .tabs_box {
    padding: 26px 26px 0;
  }
  .tips {
    margin: 0 0 26px;
  }
  .page_content {
    padding: 0px 26px 26px;
    .page_area_title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 13px;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .form_box {
      margin-bottom: 23px;
       .el-form-item {
        label {
          width: 110px !important;
        }
        .el-form-item__content {
          margin-left: 110px !important;
        }
      }
       .el-select {
        width: 100%;
      }
      .upload_img_box {
        margin-bottom: 50px;
         .el-form-item .el-form-item__content {
          margin-left: 0 !important;
        }
        .upload_img_tags_text {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 10px;
        }
      }
      .text_area_box {
         .el-form-item .el-form-item__content {
          margin-left: 0 !important;
        }
         .el-textarea textarea {
          height: 106px;
        }
      }
    }
  }
}
</style>