<template>
  <div class="recruitInfoItem" @click.stop="goDetails">
    <div class="work_top_box">
      <div class="inner_left_box">
        <div class="work_name">{{ itemData.name }}</div>
      </div>
      <div class="inner_right_classify" v-if="itemType!='home'">
        {{ itemData.category_string }}
      </div>
    </div>
    <div class="line" v-if="itemType == 'list'"></div>
    <div class="work_center_box">
      <div class="inner_center_left">
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">采购要求:</div>
          <div class="bids_ep_row_text">{{ itemData.desc.slice(0, 10) }}...</div>
        </div>
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">地点：</div>
          <div class="bids_ep_row_text">{{ itemData.area }}</div>
        </div>
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">截止时间：</div>
          <div class="bids_ep_row_text">{{ itemData.end_date }}</div>
        </div>
      </div>
      <div class="home_btn_box" v-if="itemType == 'home'">
        <button class="goSendPrice">去报价</button>
        <button class="goCantactPhone">联系TA</button>
      </div>
      <div class="bottom_btn_box" v-if="itemType == 'op'">
        <!-- 如果审核不通过 -->
        <div class="refuse_reason" v-if="itemData.status == 'refused'">审核意见：{{ itemData.refuse_reason }}</div>
        <div class="refuse_reason" v-else></div>
        <div class="item_btn_box">
          <button class="q_blue_btn" @click.stop="goPirceDetails" v-if="itemData.status != 'wait' && itemData.status != 'refused'">查看报价</button>
          <button class="q_blue_btn" @click.stop="pauseOrOpenPackage('down')" v-if="itemData.status == 'accept'">下架招采</button>
          <button class="q_blue_btn" @click.stop="pauseOrOpenPackage('accept')" v-if="itemData.status == 'down'">重新上架</button>
          <button class="blue_btn" @click.stop="goDetails">采购详情</button>
        </div>
      </div>
      <div class="bottom_btn_box" v-if="itemType == 'myPrice'">
        <div class="time_box">报价时间：{{ itemData.price_create_time }}</div>
        <div class="item_btn_box">
          <button class="q_blue_btn" @click.stop.stop="goPirceDetails">我的报价</button>
          <button class="blue_btn" @click.stop="goDetails">采购详情</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'recruitInfoItem',
  props: {
    //list列表 查看   home首页列表 op 我的分包，有底部按钮，可操作
    //我的报价 myPrice
    itemType: {
      type: String,
      default: 'list'
    },
    itemData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {};
  },
  methods: {
    goDetails () {
      this.$router.push({
        path: '/recruitInfo/details',
        query: {
          id: this.itemData.id,
        }
      })
    },
    goPirceDetails () {
      if (this.itemType == 'myPrice') {
        uni.navigateTo({
          url: `/pages/recruitPage/checkPrice?id=${this.itemData.id}&type=myPrice`
        });
      } else {
        uni.navigateTo({
          url: `/pages/recruitPage/checkPrice?id=${this.itemData.id}&type=op`
        });
      }
    },
    pauseOrOpenPackage (type) {
      this.$api.downPurchase({ id: this.itemData.id, status: type }).then(res => {
        if (res.code == 1) {
          if (type == 'accept') {
            this.$message.success('上架成功!');
          } else {
            this.$message.success('下架成功!');
          }
          setTimeout(() => {
            this.$emit('changeData');
          }, 800);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.recruitInfoItem {
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #e4e9ff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  position: relative;
  border: 1px solid #e5e5e5;
  padding: 21px 24px;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 0px 12px 0px #c5c8d5;
  }
  .work_top_box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
    .inner_left_box {
      .work_name {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 8px;
      }
      .work_require {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
      }
    }
    .inner_right_classify {
      font-size: 18px;
      color: #3f63ff;
    }
  }

  .work_center_box {
    display: flex;
    justify-content: space-between;
    .inner_center_left {
      flex: none;
    }
    .bids_ep_row_box {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 8px;
      .bids_ep_row_title {
        color: #999999;
      }
      .bids_ep_row_text {
        color: #333333;
      }
    }
  }
  .home_btn_box {
    position: absolute;
    top: 30px;
    right: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .goSendPrice {
      width: 84px;
      height: 28px;
      background: #3f63ff;
      border-radius: 8px 8px 8px 8px;
      color: #fff;
      font-size: 14px;
      opacity: 1;
      margin-bottom: 10px;
    }
    .goCantactPhone {
      background-color: #fff;
      font-size: 14px;
      font-weight: 500;
      color: #3f63ff;
      margin: 0 auto;
      text-align: center;
    }
  }
  .bottom_btn_box {
    .time_box {
      margin-bottom: 13px;
    }
    .refuse_reason {
      font-size: 14px;
      font-weight: 400;
      color: #ff3d3d;
      margin-right: 10px;
    }
    .item_btn_box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: none;
      gap: 0 10px;

      .blue_btn {
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 14px;
      }
      .q_blue_btn {
      }
    }
  }
}
</style>
