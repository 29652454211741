<template>
  <div class="myProduct">
    <div class="page_title">
      我的产品
    </div>
    <div class="tabs_box">
      <Tabs :tabData="statusList" :gapSize="38" @handleClick="changeStatus" />
    </div>
    <div class="list_box">
      <template v-if="list_data.lists.length">
        <productList :lists="list_data.lists" :numberRow="1" itemType="op" @changeData="getData"></productList>
      </template>
      <el-empty :image-size="200" v-else></el-empty>

    </div>
    <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
  </div>
</template>
  
<script>
import Tabs from '@/components/Tabs.vue'
import mixin from '../../../mixin'
import productList from '../../../components/product/productList.vue'
export default {
  mixins: [mixin],
  components: {
    Tabs,
    productList
  },
  data () {
    return {
      curStatus: 1,
      statusList: [
        {
          text: '已上架',
          value: 1,
          active: true
        },
        {
          text: '已下架',
          value: 0,
          active: false
        },
      ]
    }
  },
  methods: {
    changeStatus (index) {
      this.statusList.forEach((item) => {
        item.active = false
      })
      this.statusList[index].active = true
      this.curStatus = this.statusList[index].value;
      this.getData();
    },
    getData () {
      this.getList('getMyProductList', this.page, { on_sale: this.curStatus });
    },
  },
  created () {
    this.getData();
  },
  mounted () {
  },
  watch: {

  },
}
</script>
  
<style lang='scss'>
.myProduct {
  padding: 26px;
  .page_title {
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    margin-bottom: 20px;
  }
  .tabs_box {
    margin-bottom: 30px;
  }
  .list_box {
    min-height: 500px;
  }
}
</style>