<template>
  <div class="collect">
    <div class="title">我的收藏</div>
    <div class="collect_bar">
      <Tabs :tabData="collectBar" @handleClick="changeHandle" />
    </div>
    <div class="panel">
      <template v-if="list_data.lists.length">
        <jobList :numberRow="1" :lists="list_data.lists" v-if="cur_tabs_type == 'getCollectJobList'"></jobList>
        <introduceComList :numberRow="1" :lists="list_data.lists" v-if="cur_tabs_type == 'getCollectResumeList'"></introduceComList>
        <purchaseList :numberRow="1" :lists="list_data.lists" v-if="cur_tabs_type == 'getCollectPurchaseList'"></purchaseList>
        <subcontractList :numberRow="1" :lists="list_data.lists" v-if="cur_tabs_type == 'getCollectPackageList'"></subcontractList>
        <machineList :numberRow="1" :lists="list_data.lists" v-if="cur_tabs_type == 'getCollectMechaniseList'"></machineList>
        <proList :numberRow="3" :lists="list_data.lists" v-if="cur_tabs_type == 'getCollectProductList'"></proList>
      </template>
      <el-empty :image-size="200" v-else></el-empty>
      <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
    </div>
  </div>
</template>
  
<script>
import Tabs from '../components/tabs'
import jobList from '@/components/job/jobList.vue'
import introduceComList from '@/components/introduce/introduceComList.vue'
import purchaseList from '@/components/purchase/purchaseList.vue'
import subcontractList from '@/components/subcontract/SubcontractList.vue'
import machineList from '@/components/machine/machineList.vue'
import proList from "@/components/product/proList.vue"
import mixin from '../../../mixin'
export default {
  mixins: [mixin],
  components: {
    Tabs,
    jobList,
    introduceComList,
    purchaseList,
    subcontractList,
    machineList,
    proList
  },
  data () {
    return {
      collectBar: [{
        id: 1,
        text: '招工',
        active: true,
        value: 'getCollectJobList',
      },
      {
        id: 2,
        text: '简历',
        active: false,
        value: 'getCollectResumeList',

      },
      {
        id: 3,
        text: '分包',
        active: false,
        value: 'getCollectPackageList',
      },
      {
        id: 4,
        text: '招采',
        value: 'getCollectPurchaseList',
        active: false
      },
      {
        id: 5,
        text: '机械',
        active: false,
        value: 'getCollectMechaniseList',
      },
      {
        id: 6,
        text: '产品',
        value: 'getCollectProductList',
        active: false
      }
      ],
      cur_tabs_type: 'getCollectJobList'
    }
  },
  created () {
    this.getData();
  },
  methods: {
    changeHandle (index) {
      this.collectBar.forEach((item) => {
        item.active = false
      })
      this.collectBar[index].active = true;
      this.cur_tabs_type = this.collectBar[index].value;
      this.page.page = 1;
      this.list_data.lists = [];
      this.getData();
    },
    getData () {
      this.getList(this.cur_tabs_type, this.page);
    }
  }
}
</script>
  
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.collect {
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;

  .title {
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    line-height: 26px;
    padding: 18px 25px 18px 9px;
    border-bottom: 1px solid #e5e5e5;
    margin: 0 25px 0 9px;
  }

  .collect_bar {
    margin: 30px 0 0 27px;
  }

  .panel {
    display: flex;
    flex-direction: column;
    gap: 12px 0;
    padding: 33px 22px 43px 28px;

    .panelItem {
      padding: 0 23px 22px 23px;
      background: #ffffff;
      box-shadow: 0px 0px 5px 0px #e4e9ff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #e5e5e5;

      .item_date {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
        padding: 15px 0;
        border-bottom: 1px solid #e5e5e5;
      }

      .item_salary {
        display: flex;
        justify-content: space-between;
        padding: 17px 0 4px;

        div {
          &:first-child {
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
          }

          &:last-child {
            font-size: 13px;
            font-weight: 500;
            color: #ff5100;
            line-height: 16px;

            span {
              font-size: 22px;
              font-weight: 500;
            }
          }
        }
      }

      .item_desc {
        padding: 11px 2px 15px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
      }

      .item_det_pos {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 24px;

        span {
          color: #333;
        }
      }
    }
  }
}
</style>