<template>
  <div class="smallOpenVip">
    <div class="mask_box_bg">
      <div class="open_vip_small_box">
        <div class="open_vip_small_box_title">
          查看该信息需要开通/升级会员
        </div>
        <div class="open_vip_small_box_btn" @click="goLink('/personalCenter/member')">
          立即开通会员
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goLink (router) {
      this.$router.push(router);
    },
  }
}
</script>

<style lang="scss">
.smallOpenVip {
  .mask_box_bg {
    width: 100%;
    height: calc(100% - 48px);
    position: absolute;
    top: 48px;
    left: 0;
    backdrop-filter: blur(5px);
    .open_vip_small_box {
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 403px;
      height: 178px;
      background: linear-gradient(
        179deg,
        #cee3fc 0%,
        #ffffff 50%,
        #ffffff 100%
      );
      border-radius: 10px;
      opacity: 1;
      border: 1px solid #b2d2f9;
      box-sizing: border-box;
      padding: 31px 35px;
      .open_vip_small_box_title {
        font-size: 24px;
        font-weight: 500;
        color: #3f63ff;
        text-align: center;
        margin-bottom: 34px;
      }
      .open_vip_small_box_btn {
        width: 185px;
        height: 41px;
        cursor: pointer;
        background: #3f63ff;
        border-radius: 5px;
        opacity: 1;
        border: 1px solid #3f63ff;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }
}
</style>