
    <template>
  <div class="proSupply">
    <div class="w">
      <topFilterBox @change="chooseCity">
        <div class="filter_box" slot="first">
          <div class="filter_title">
            产品分类:
          </div>
          <div class="filter_content">
            <div :class="['filter_c_item',filter.category_id==pItem.id? 'filter_active_item' : '']"
              v-for="(pItem,pIndex) in $store.state.categoryConfig.product" :key="pIndex" @click="chooseType(pItem,'category_id')">
              <button class="filter_c_item_name">
                {{ pItem.name }}
              </button>
            </div>
          </div>
        </div>
      </topFilterBox>
      <div class="list_box">
        <div class="lists_total_num_box">
          共找到<span>{{ list_data.total }}</span>条信息
        </div>
        <proList itemType="list" :lists="list_data.lists" :numberRow="5" v-if="list_data.lists.length"></proList>
        <el-empty :image-size="200" v-else></el-empty>
      </div>
      <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
    </div>
  </div>
</template>

<script>
import topFilterBox from '../../components/topFilterBox.vue';
import proList from '../../components/product/proList.vue';
import Pager from '../../components/pager/Pager.vue'
import mixin from '../../mixin/index'
import { mapState } from 'vuex';
export default {
  mixins: [mixin],
  components: {
    proList,
    topFilterBox,
    Pager,
  },
  data () {
    return {
      cur_father: {},//记录当前选择的省份
      cur_fatherIndex: -1,//记录当前选择的省份下标
      cur_sons: [],//当前选择的城市
      cur_fathers_name: [],//当前选择的省份 用于显示激活css
      cur_sons_name: [],//当前选择的城市 用于显示激活css
      filter: {
        keyword: '',
        city_codes: [],
        category_id: '',
        type: ''
      },
      page: {
        page: 1,
        page_size: 20
      }
    }
  },
  created () {
    this.getData();
  },
  computed: {
    ...mapState({
      keyword: state => state.keyword,
    }),
  },
  methods: {
    chooseCity (val) {
      this.filter.city_codes = val;
      this.getData();
    },
    choosefather (index) {
      this.cur_fatherIndex = index;
    },
    hideChildren () {
      this.cur_father = {};
    },
    curChooseNum (father) {
      let findfather = this.cur_sons.find(item => item.father_name === father.name);
      if (findfather) {
        let num = 0;
        this.cur_sons.forEach(item => {
          if (item.father_name == father.name) {
            num++;
          }
        });
        return num;
      } else {
        return 0;
      }
    },
    chooseJob (son) {
      console.log(son, '当前城市');
      if (this.cur_sons_name.indexOf(son.name) == -1) {
        if (this.cur_sons_name.length >= 3) {
          this.$message.error('最多只能选择三个类型');
          return;
        }
        this.cur_sons_name.push(son.name);
        this.cur_sons.push({
          ...son,
          father_name: this.cur_father.name,
          father_code: this.cur_father.code
        });
      } else {
        this.cur_sons_name = this.cur_sons_name.filter(item => item != son.name);
        this.cur_sons = this.cur_sons.filter(item => item.name != son.name);
      }
      let sonCode = this.cur_sons.map(item => {
        return item.id;
      })
      this.filter.category_ids = sonCode;
      this.getData();
    },
    chooseType (val, type) {
      if (type == 'category_id') {
        if (this.filter.category_id == val.id) {
          this.filter.category_id = ''
        } else {
          this.filter.category_id = val.id;
        }
      }
      this.getData();
    },

    getData () {
      let params = {
        ...this.filter
      }
      params.city_codes += '';
      params.category_id += '';
      if (this.keyword.length) {
        params.keyword = this.keyword;
      }
      for (let key in params) {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      }
      this.getList('getProductList', this.page, params);
    }
  },
  watch: {
    keyword (val) {
      this.filter.keyword = val;
      this.getData();
    }
  }
}
</script>

<style lang="scss">
.proSupply {
  min-height: 100vh;
  padding-bottom: 92px;
  .w {
    width: 1200px;
    margin: 0 auto;
  }
  .list_box {
    .lists_total_num_box {
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      margin: 24px 0 29px;
      span {
        color: #3f63ff;
      }
    }
  }
}
</style>