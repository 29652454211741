<template>
  <div class="BidsInfo" @click="goDetails">
    <div class="bids_info_top_box">
      <div class="bids_status">{{ bidsTypeText[bidsType] }}</div>
      <div class="bids_time_text">{{ itemData.create_time }}</div>
    </div>
    <div class="bids_info_desc_box">
      <div class="bids_name">{{ itemData.project_name }}</div>
      <div class="bids_ep_info">
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">地区：</div>
          <div class="bids_ep_row_text">{{ itemData.area }}</div>
        </div>
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">金额：</div>
          <div class="row_text" v-if="userInfo.vip.vip_level == 0 || userInfo.vip.vip_level == -1||!userInfo.vip.vip_level">
            {{replaceWithAsterisks(itemData.money)  }}元</div>
          <div class="row_text" v-else>{{itemData.money}}元</div>
        </div>
        <div class="bids_ep_row_box" v-if="bidsType=='invitation'">
          <div class="bids_ep_row_title">招标单位：</div>
          <div class="bids_ep_row_text">{{ itemData.company_name }}</div>
        </div>
        <div class="bids_ep_row_box" v-else>
          <div class="bids_ep_row_title">中标单位：</div>
          <div class="bids_ep_row_text">{{ itemData.win_company }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'BidsInfo',
  props: {
    itemData: {
      type: Object,
      default: () => ({})
    },
    bidsType: {
      type: String,
      default: 'invitation'
    }
  },
  data () {
    return {
      bidsTypeText: {
        invitation: '招标公告',
        win: '中标通知'
      }
    };
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    goDetails () {
      this.$router.push({
        path: '/callBids/details',
        query: {
          id: this.itemData.id,
          type: this.bidsType
        }
      })
    }
  }
};
</script>

<style lang="scss">
.BidsInfo {
  background: #ffffff;
  border-radius: 8px;
  padding: 22px 23px;

  box-shadow: 0px 0px 5px 0px #c5c8d5;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 0px 12px 0px #c5c8d5;
  }
  .bids_info_top_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    .bids_status {
      background: #ffede3;
      border-radius: 5px;
      font-size: 15px;
      padding: 4px 6px;
      color: #ff5100;
    }
    .bids_time_text {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }
  .bids_info_desc_box {
    .bids_name {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      line-height: 25px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .bids_ep_info {
      background: #f5f6fd;
      border-radius: 6px;
      padding: 4px 11px;
      .bids_ep_row_box {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 5px;
        .bids_ep_row_title {
          color: #999999;
        }
        .bids_ep_row_text {
          color: #333333;
        }
      }
    }
  }
}
</style>
