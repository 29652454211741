<template>
  <div class="BidsList">
    <List :numberRow="numberRow" :gutter="gutter">
      <div class="bids_item" v-for="(item, index) in lists" :key="index" slot="Item">
        <BidsInfo :bidsType="bidsType" :itemData="item"></BidsInfo>
      </div>
    </List>
  </div>
</template>

<script>
import BidsInfo from './BidsInfo.vue';
import List from '../List.vue'
export default {
  name: 'BidsList',
  components: { BidsInfo, List },
  props: {
    numberRow: {
      type: Number,
      default: 2
    },
    gutter: {
      type: Number,
      default: 20
    },
    lists: {
      type: Array,
      default: () => ([])
    },
    bidsType: {
      type: String,
      default: 'invitation'
    }
  },
  data () {
    return {};
  },

};
</script>

<style lang="scss">
.BidsList {
  .bids_item {
  }
}
</style>
