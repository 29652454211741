<template>
  <div class="regionSelect">
    <el-cascader ref="cascaderAddr" :options="cityArr" filterable v-model="reigon" @change="changeRegion"
      @getCheckedNodes="getCheckedNodes"></el-cascader>
  </div>
</template>

<script>
import CityJson from '../common/js/city.json'
export default {
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      reigon: '',
      cityArr: CityJson
    };
  },
  mounted () {
    // console.log(this.CityJson);
    // this.cityArr.forEach(item => {
    //   console.log(item.value);
    //   if (item.value.length < 6) {
    //     item.value = item.value.toString().padEnd(6, '0');
    //   }
    //   if (item.children.length) {
    //     item.children.forEach(elem => {
    //       if (elem.value.length !== 6) {
    //         elem.value = elem.value.toString().padEnd(6, '0');
    //       }
    //     })
    //   }
    // })
    // console.log(this.cityArr);

  },
  methods: {
    changeRegion (val) {
      setTimeout(() => {
        this.$emit('changeRegion', {
          area: this.$refs.cascaderAddr.inputValue,
          area_code: val
        });
      }, 100)
    },
    getCheckedNodes (val) {
      console.log(val, '当前国内外哦给我');
    }
  },
  watch: {
    value () {
      this.reigon = this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>