<template>
  <div class="personalCenter">
    <div class="personal_center_head">
      <div class="head_profile">
        <div class="avatar">
          <div class="vip_logo" @click="jumpLink('/personalCenter/basicInfo')">
            <img :src="userInfo.avatar" alt="" class="icon-80" v-if="userInfo.avatar">
            <img src="@/assets/img/icon/img_basicInfo_avatar.png" alt="" class="icon-80" v-else>
          </div>
          <div class="king_logo" v-if="$store.state.userInfo.vip.vip_level&&$store.state.userInfo.vip.vip_level!=0">
            <img src="@/assets/img/icon/icon_king.png" alt="">
          </div>
        </div>
        <div class="name">
          <span v-if="userInfo.nickname">{{ userInfo.nickname }}</span>
          <span v-else>默认昵称</span>
        </div>
        <div class="tel" v-if="userInfo.mobile">{{ userInfo.mobile }}</div>
      </div>
      <div class="head_bar">
        <SidePanel @click.native="jumpLink(item.router)" v-for="(item, index) in headBarData" :key="index" :title="item.title"
          :description="item.description" :active="item.active" :rect="item.rect" :circle="item.circle" :centerText="item.centerText" />
      </div>
    </div>
    <div class="personal_center_container"><router-view></router-view></div>
  </div>
</template>
  
<script>
import SidePanel from './components/sidePanel';
export default {
  components: {
    SidePanel,
  },
  data () {
    return {
      curRouter: '/personalCenter/basicInfo',
      headBarData: [
        { router: "/personalCenter/member", title: 'VIP会员', description: '开通会员获取一手信息', centerText: '' },
        { router: "/personalCenter/enterpriseAuth", title: '企业认证', description: '认证完成后可发布招标、用工等信息', rect: '待认证' },
        { router: "/personalCenter/realName", title: '个人实名', description: '资料完善后可创建个人简历', rect: '待实名' },
        { router: "/personalCenter/notices", title: '消息', circle: false },
        { router: "/personalCenter/collect", title: '我的收藏', description: '' },
        { router: "/personalCenter/messageReturn", title: '意见反馈', description: '' },
        { router: "/personalCenter/QA", title: '常见问题', description: '' },
        { router: "/personalCenter/updateTel", title: '更改手机号', description: '' },
        { router: "/personalCenter/vipOrder", title: '会员订单', description: '' },
      ],
      userInfo: {
        personal_auth: '', //是否完成个人认证，0=未认证，1=已认证，2=审核中，3-审核拒绝
        company_auth: '', //是否完成企业认证，0=未认证，1=已认证，2=审核中，3-审核拒绝
        company_vip: '' //是否是企业vip，0=否，1=是，2=已过期
      },
      type_text: {
        0: '未认证',
        1: '已认证',
        2: '审核中',
        3: '审核拒绝'
      }
    }
  },
  methods: {
    jumpLink (router) {
      this.$router.push(router);
    },
    changeCur (path) {
      this.headBarData.forEach((item) => {
        item.active = false;
        if (path.includes(item.router)) {
          this.curRouter = item.router;
          item.active = true;
          return;
        }
        this.$forceUpdate();
      });
    },
    getUserInfo () {
      this.$api.getMemberInfo().then(res => {
        localStorage.setItem('userInfo', JSON.stringify(res.data));
        this.userInfo = res.data;
        if (this.userInfo) {
          if (this.userInfo) {
            switch (this.userInfo.company_vip) {
              case 0:
                this.headBarData[0].centerText = '未开通';
                break;
              case 1:
                this.headBarData[0].description = '';
                this.headBarData[0].centerText = `${this.userInfo.vip.expire_time} 到期`;
                break;
              case 2:
                this.headBarData[0].description = '续开会员获取一手信息';
                this.headBarData[0].centerText = `${this.userInfo.vip.expire_time} 过期`;
                break;
            }
            this.headBarData[1].rect = this.type_text[this.userInfo.company_auth];
            this.headBarData[2].rect = this.type_text[this.userInfo.personal_auth];
          }
        }
      });
    },
    getSysMsgCount () {
      this.$api.getSysMsgCount().then(res => {
        let haveNewMsg = false;
        if (res.data.length) {
          res.data.forEach(item => {
            if (item.msg && item.msg.is_read == 0) {
              haveNewMsg = true;
            }
          })
        }
        if (haveNewMsg) {
          this.headBarData[3].circle = true;
        } else {
          this.headBarData[3].circle = false;
        }
      })
    },
  },
  mounted () {
    this.changeCur(this.$route.path);
    this.getUserInfo();
    this.getSysMsgCount();
  },
  watch: {
    $route (val) {
      this.changeCur(val.path);
    },
  },
}
</script>
  
<style lang='scss'>
* {
  box-sizing: border-box;
}

.personalCenter {
  margin: 0 auto 70px;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  gap: 14px 0;
  margin-top: 31px;
}

.personal_center_head {
  width: 360px;
  background: linear-gradient(180deg, #2e2e48 6%, #3b496d 100%);
  border-radius: 8px 8px 8px 8px;
  padding-bottom: 50px;
  height: fit-content;
  flex: none;
}

.personal_center_container {
  flex: 1;
  margin-left: 15px;
  min-height: 707px;
}

.head_profile {
  height: 226px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #f1f2f3;
    position: relative;
    margin-top: 76px;
    cursor: pointer;

    .vip_logo {
      position: absolute;
      img {
        border-radius: 50%;
      }
    }
    .king_logo {
      position: absolute;
      top: -12px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 26px;
      height: 21px;
      img {
        width: 26px;
        height: 21px;
      }
    }
  }

  .name {
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    margin: 12px 0 6px;
  }
}
</style>