<template>
  <div class="bidsDesc w">
    <div class="bids_desc_top_info">
      <div class="bids_desc_name">{{detailsData.project_name}}</div>
      <div class="bids_area_box">
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">地区：</div>
          <div class="bids_ep_row_text">{{detailsData.area}}</div>
        </div>
        <div class="bids_ep_row_box">
          <div class="bids_ep_row_title">发布时间：</div>
          <div class="bids_ep_row_text">{{detailsData.create_time}}</div>
        </div>
      </div>
    </div>
    <div class="desc_content">
      <!-- 摘要盒子 -->
      <div class="area_box">
        <div class="area-title">摘要</div>
        <div class="row_list_box">
          <div class="row_box">
            <div class="row_title">项目地区：</div>
            <div class="row_text">{{detailsData.area }}</div>
          </div>
          <div class="row_box">
            <div class="row_title">项目类型：</div>
            <div class="row_text">{{ detailsData.name }}</div>
          </div>
          <div class="row_box">
            <div class="row_title">项目金额：</div>
            <div class="row_text" v-if="auth!='pass'">{{replaceWithAsterisks(detailsData.money)  }}元</div>
            <div class="row_text" v-else>{{detailsData.money}}元</div>
          </div>
          <div class="row_box">
            <div class="row_title">报名终止日期：</div>
            <div class="row_text">{{detailsData.end_date}}</div>
          </div>
        </div>
      </div>

      <!-- 联系盒子 -->
      <div class="area_box min-h-285">
        <div class="area-title">联系人</div>
        <div class="row_list_box" v-if="auth=='pass'">
          <div class="row_box">
            <div class="row_title">招标单位：</div>
            <div class="row_text">{{  detailsData.company_name}}</div>
          </div>
          <div class="row_box">
            <div class="row_title">联系人：</div>
            <div class="row_text">{{ detailsData.contact }}</div>
          </div>
          <div class="row_box">
            <div class="row_title">联系方式：</div>
            <div class="row_text"><span class="fn-cl-20417C">{{ detailsData.contact_phone }}</span></div>
          </div>
          <div class="row_box" v-if="type=='win'">
            <div class="row_title">中标单位：</div>
            <div class="row_text"><span class="fn-cl-20417C">{{ detailsData.win_company }}</span></div>
          </div>
        </div>
        <div class="row_list_box" v-else>
          <div class="row_box">
            <div class="row_title">招标单位：</div>
            <div class="row_text">*******</div>
          </div>
          <div class="row_box">
            <div class="row_title">联系人：</div>
            <div class="row_text">*****</div>
          </div>
          <div class="row_box">
            <div class="row_title">联系方式：</div>
            <div class="row_text"><span class="fn-cl-20417C">*********</span></div>
          </div>
        </div>
        <smallOpenVip v-if="auth!=='pass'"></smallOpenVip>
      </div>
      <!-- 附件盒子 -->
      <div class="area_box min-h-285">
        <div class="area-title">附件</div>
        <div class="files_list_box" v-if="auth=='pass'">
          <div class="files_item" v-for="(item,index) in detailsData.images_view" :key="index">
            <img :src="item" />
          </div>
        </div>
        <smallOpenVip v-if="auth!=='pass'"></smallOpenVip>
      </div>
      <!-- 详情盒子 -->
      <div class="area_box bids_details_box">
        <div class="area-title">详情</div>
        <div class="inner_desc_box" v-if="detailsData.desc">
          {{detailsData.desc}}
        </div>
        <div class="inner_desc_box" v-else>
          ****************************************************************************
        </div>
        <BigOpenVip :visible="true" v-if="auth!=='pass'"></BigOpenVip>
      </div>
    </div>
  </div>
</template>

<script>
import smallOpenVip from '../../components/vipPopup/smallOpenVip.vue';
export default {
  components: {
    smallOpenVip,
  },
  data () {
    return {
      vipShow: true,
      approveShow: true,
      id: '',
      type: '',
      auth: '',
      detailsData: {}
    };
  },
  created () {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.getDetails();
  },
  methods: {
    changeTab (idx) {
      this.curTabs = idx;
    },
    closeVipShow () {
      this.vipShow = false;
    },
    closeApproveShow () {
      this.approveShow = false;
    },
    getDetails () {
      if (this.type == 'invitation') {
        this.$api.getInvitationDetail({ id: this.id }).then(res => {
          console.log(res, 'agwa');
          this.detailsData = res.data.detail;
          this.auth = res.data.auth;
        })
      } else {
        this.$api.getWinDetail({ id: this.id }).then(res => {
          console.log(res, 'agwa');
          this.detailsData = res.data.detail;
          this.auth = res.data.auth;
          console.log(res.data.auth);
        })
      }

    },

  }
};
</script>

<style lang="scss">
.bidsDesc {
  min-height: 100vh;
  background-color: #f4f6fa;
  box-sizing: border-box;
  padding: 31px 0px;
  .bids_desc_top_info {
    background: #ffffff;
    border-radius: 8px;
    padding: 25px 19px;
    margin-bottom: 10px;
    .bids_desc_name {
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
    }
    .bids_area_box {
      display: flex;
      align-items: center;
      gap: 10px 30px;
      flex-wrap: wrap;
      .bids_ep_row_box {
        display: flex;
        align-items: center;
        font-size: 14px;
        .bids_ep_row_title {
          color: #999999;
        }
        .bids_ep_row_text {
          color: #333333;
        }
      }
    }
  }
  .desc_content {
    .area_box {
      position: relative;
    }
    .bids_details_box {
      min-height: 612px;
    }
    .desc_tabs {
      margin-bottom: 40px;
    }
    .row_list_box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }
    .inner_desc_box {
      font-size: 15px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }
    .files_list_box {
      .files_item {
        width: 100%;
        height: 700px;
      }
    }
  }

  //认证提醒
  .approve_box {
    width: 596px;
    box-sizing: border-box;
    background: linear-gradient(180deg, #cee3fc 0%, #ffffff 40%, #ffffff 100%);
    border-radius: 20px;
    opacity: 1;
    padding: 40px 48px;
    text-align: center;
    color: #333333;
    .approve_title {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 52px;
    }
    .approve_text {
      font-size: 30px;
      margin-bottom: 88px;
    }
    .approve_btn_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cancel_btn,
      .comfirm_btn {
        width: 240px;
        height: 68px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        background: #f6f6f6;
        border-radius: 10px;
        border: 2px solid #cecece;
        color: #333;
      }
      .comfirm_btn {
        background: #3f63ff;
        color: #ffffff;
      }
    }
  }
}
</style>
