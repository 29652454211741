<template>
  <el-dialog :visible.sync="visible" center width="25%" title="认证提示" :before-close="handleClose">
    <div class="authPassPopupBox">
      <div class="auth_text_box">
        请先上传企业营业执照完成企业认证!
      </div>
      <div class="auth_btn_box">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="goLink('/personalCenter/enterpriseAuth')">去认证</el-button>
      </div>
    </div>

  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialogVisible: false
    };
  },
  methods: {
    handleClose () {
      this.$emit('update:visible', false);
    },
    goLink (router) {
      this.$emit('update:visible', false);
      this.$router.push(router);
    }
  },
};
</script>

<style lang="scss" scoped>
.el-dialog__header {
  background: linear-gradient(180deg, #cee3fc 0%, #ffffff 100%);
}
.authPassPopupBox {
  .auth_text_box {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 40px;
    text-align: center;
  }
  .auth_btn_box {
    display: flex;
    justify-content: center;
    button {
      &:last-child {
        margin-left: 40px;
      }
    }
  }
}
</style>