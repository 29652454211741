<template>
  <div :class="['bar',showBorder?'borderBottom':'']" :style="{gap:gapSize?`0 ${gapSize}px`:'0 30px'}">
    <div @click="clickFun(index)" v-for="(item, index) in tabData" :key="index" class="barItem"
      :class="[item.active ? 'active' : '',size=='big'?'bigItem':'',fnSz?`fn-sz-${fnSz}`:'']"
      :style="{paddingBottom:paddingBottom?`${paddingBottom}px`:'5px'}">
      {{ item.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['tabData', 'handleClick', 'showBorder', 'size', 'gapSize', 'fnSz', 'paddingBottom'],
  methods: {
    clickFun (index) {
      this.$emit('handleClick', index);
    }
  }

}
</script>

<style lang="scss" scoped>
.bar {
  display: flex;
  width: 100%;

  .barItem {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    line-height: 26px;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    &.active {
      position: relative;
      width: fit-content;
      &::after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        height: 3px;
        width: 50%;
        margin: 0 auto;
        background-color: #3f63ff;
      }
    }
  }
  .bigItem {
    font-size: 22px;
  }
}
.borderBottom {
  border-bottom: 1px solid #e5e5e5;
}
</style>