<template>
  <div class="sidePanel" :class="active ? 'active' : ''">
    <div class="panel_left">
      <div class="detail">
        <div class="title">{{ title }}</div>
        <div v-if="description" class="description">{{ description }}</div>
      </div>
      <div class="center">
        <div v-show="centerText">
          {{ centerText }}
        </div>
        <template v-if="rect">
          <Rectangle :text="rect" />
        </template>
        <div v-if="circle" class="circle"></div>
      </div>
    </div>
    <div class="right">
      <img class="icon-16" src="@/assets/img/icon/icon_white_right_arr.png" alt="">
    </div>
  </div>
</template>
  
<script>
import Rectangle from '../../components/rectangle'
export default {
  components: {
    Rectangle
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: 'white'
    },
    active: {
      type: Boolean,
      default: () => false
    },
    rect: {
      type: String,
      default: ""
    },
    centerText: {
      type: String,
      default: ""
    },
    circle: {
      type: Boolean,
      default: () => false
    }
  },
}
</script>
  
<style lang='scss' scoped>
.sidePanel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-right: 13px;
  height: 59px;
  box-sizing: border-box;
  &.active {
    background: #3f63ff;
  }
}

.circle {
  width: 11px;
  height: 11px;
  background: #fa5151;
  border-radius: 50%;
}

.panel_left {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding: 10px 16px 10px 19px;
  align-items: center;

  .detail {
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 19px;
    }

    .description {
      margin-top: 9px;
      font-size: 12px;
      font-weight: 400;
      color: #aeaeae;
      line-height: 11px;
    }
  }

  .center {
    font-size: 14px;
    font-weight: 500;
    color: #ff7b00;
    line-height: 11px;
  }
}
</style>