<template>
  <div :class="['bigOpenVip',isFull?'full_screen':'']" v-if="visible">
    <div class="mask_box_bg" @click.stop="closeModelBox">
      <div class="open_vip_box">
        <div class="open_vip_content">
          <div class="fn-sz-20 fn-cl-333333 fn-weight-600  mar-bottom-10 text-center">查看该信息需要开通/升级会员</div>
          <div class="fn-sz-14 fn-cl-414A6B mar-bottom-26 text-center">开通/升级vip查看更多项目信息</div>
          <div class="open_vip_image">
            <img src="../../assets/img/icon/bg_open_vip.png" mode="" />
            <div class="open_vip_button" @click.stop="goLink('/personalCenter/member')">开通/升级会员</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isFull: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goLink (router) {
      this.closeModelBox();
      this.$router.push(router);
    },
    closeModelBox () {
      //只有全屏的才能点击遮幕关闭
      if (this.isFull) {
        this.$emit('update:visible', false);
      }
    }
  }
}
</script>

<style lang="scss">
.bigOpenVip {
  .mask_box_bg {
    width: 100%;
    height: calc(100% - 48px);
    position: absolute;
    top: 48px;
    left: 0;
    backdrop-filter: blur(5px);
    // 开通vip弹窗
    .open_vip_box {
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background: linear-gradient(
        180deg,
        #cee3fc 0%,
        #ffffff 50%,
        #ffffff 100%
      );
      padding: 62px 45px;
      border-radius: 10px;
      width: 403px;
      height: 489px;
      box-sizing: border-box;
      border: 1px solid #b2d2f9;
      .open_vip_content {
        .open_vip_image {
          position: relative;
          image {
            width: 320px;
            height: 271px;
          }
          .open_vip_button {
            position: absolute;
            cursor: pointer;
            left: 0;
            right: 0;
            bottom: 28px;
            width: 244px;
            height: 40px;
            margin: 0 auto;
            background: linear-gradient(180deg, #fae7c1 2%, #f9b653 100%);
            border-radius: 10px;
            color: #673003;
            font-size: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
.full_screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  .mask_box_bg {
    height: 100%;
    top: 0;
    left: 0;
    .open_vip_box {
      top: 200px;
    }
  }
}
</style>