import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },

  {
    path: "/personalCenter",
    redirect: "/personalCenter/member",
  },
  {
    path: "/workbench",
    redirect: "/workbench/myIntroduce",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/login/index.vue"),
  },
  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: () => import("../pages/userAgreement/index.vue")
  },
  {
    path: '/privacyAgreement',
    name: 'privacyAgreement',
    component: () => import("../pages/privacyAgreement/index.vue")
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../pages/home/index.vue"),
  },
  {
    path: "/callBids",
    name: "callBids",
    component: () => import("../pages/callBids/index.vue"),
  },
  {
    path: '/callBids/details',
    name: 'bisDetails',
    component: () => import('../pages/callBids/details.vue')
  },
  {
    path: "/getBids",
    name: "getBids",
    component: () => import("../pages/getBids/index.vue"),
  },
  {
    path: "/recruitJob",
    name: "recruitJob",
    component: () => import("../pages/recruitJob/index.vue"),
  },
  {
    path: "/recruitJob/workDetails",
    name: "workDetails",
    component: () => import("../pages/recruitJob/workDetails.vue"),
  },
  {
    path: "/recruitJob/introduceDetails",
    name: "introduceDetails",
    component: () => import("../pages/recruitJob/introduceDetails.vue"),
  },
  {
    path: "/serviceSubcontract",
    name: "serviceSubcontract",
    component: () => import("../pages/serviceSubcontract/index.vue"),
  },
  {
    path: "/serviceSubcontract/details",
    name: "serviceSubcontractDetails",
    component: () => import("../pages/serviceSubcontract/details.vue"),
  },
  {
    path: "/machineRent",
    name: "machineRent",
    component: () => import("../pages/machineRent/index.vue"),
  },
  {
    path: "/machineRent/details",
    name: "machineRentDetails",
    component: () => import("../pages/machineRent/details.vue"),
  },
  {
    path: "/proSupply",
    name: "proSupply",
    component: () => import("../pages/proSupply/index.vue"),
  },
  {
    path: "/proSupply/details",
    name: "proSupplyDetails",
    component: () => import("../pages/proSupply/details.vue"),
  },
  {
    path: "/recruitInfo",
    name: "recruitInfo",
    component: () => import("../pages/recruitInfo/index.vue"),
  },
  {
    path: "/recruitInfo/details",
    name: "recruitInfoDetails",
    component: () => import("../pages/recruitInfo/details.vue"),
  },
  {
    path: "/ep-details",
    name: "ep-details",
    component: () => import("../pages/ep/details.vue"),
  },
  {
    path: "/check-introduce",
    name: "check-introduce",
    component: () => import("../pages/workbench/recruitWork/checkIntroduce.vue"),
  },
  {
    path: "/home-news-list",
    name: "home-news-list",
    component: () => import("../pages/news/list.vue"),
  },
  {
    path: "/home-news-details",
    name: "home-news-details",
    component: () => import("../pages/news/details.vue"),
  },
  {
    path: "/workbench",
    name: "workbench",
    component: () => import("../pages/workbench/index.vue"),
    children: [
      {
        path: '/workbench/myIntroduce',
        name: 'myIntroduce',
        component: () => import("../pages/workbench/introduce/my.vue"),
      },
      {
        path: '/workbench/myDeliver',
        name: 'myDeliver',
        component: () => import("../pages/workbench/introduce/deliver.vue"),
      }
      ,
      {
        path: '/workbench/publishBids',
        name: 'publishBids',
        component: () => import("../pages/workbench/bids/publish.vue"),
      }
      ,
      {
        path: '/workbench/myBids',
        name: 'myBids',
        component: () => import("../pages/workbench/bids/my.vue"),
      },
      {
        path: '/workbench/publishRecruitWork',
        name: 'publishRecruitWork',
        component: () => import("../pages/workbench/recruitWork/publish.vue"),
      }
      ,
      {
        path: '/workbench/myRecruitWork',
        name: 'myRecruitWork',
        component: () => import("../pages/workbench/recruitWork/my.vue"),
      }
      ,
      {
        path: '/workbench/publishSubcontract',
        name: 'publishSubcontract',
        component: () => import("../pages/workbench/subcontract/publish.vue"),
      }
      ,
      {
        path: '/workbench/mySubcontract',
        name: 'mySubcontract',
        component: () => import("../pages/workbench/subcontract/my.vue"),
      },
      {
        path: '/workbench/publishPurchase',
        name: 'publishPurchase',
        component: () => import("../pages/workbench/purchase/publish.vue"),
      }
      ,
      {
        path: '/workbench/myPurchase',
        name: 'myPurchase',
        component: () => import("../pages/workbench/purchase/my.vue"),
      }
      ,
      {
        path: '/workbench/publishMachine',
        name: 'publishMachine',
        component: () => import("../pages/workbench/machine/publish.vue"),
      }
      ,
      {
        path: '/workbench/myMachine',
        name: 'myMachine',
        component: () => import("../pages/workbench/machine/my.vue"),
      }
      ,
      {
        path: '/workbench/publishProduct',
        name: 'publishProduct',
        component: () => import("../pages/workbench/product/publish.vue"),
      }
      ,
      {
        path: '/workbench/myProduct',
        name: 'myProduct',
        component: () => import("../pages/workbench/product/my.vue"),
      }
    ]
  },
  {
    path: "/personalCenter",
    name: "personalCenter",
    component: () => import("../pages/personalCenter/index.vue"),
    children: [
      {
        path: "/personalCenter/basicInfo",
        name: "basicInfo",
        component: () => import("../pages/personalCenter/basicInfo/index.vue"),
      },
      {
        path: "/personalCenter/member",
        name: "member",
        component: () => import("../pages/personalCenter/member/index.vue"),
      },
      {
        path: "/personalCenter/enterpriseAuth",
        name: "enterpriseAuth",
        component: () =>
          import("../pages/personalCenter/enterpriseAuth/index.vue"),
      },
      {
        path: "/personalCenter/realName",
        name: "realName",
        component: () => import("../pages/personalCenter/realName/index.vue"),
      },
      {
        path: "/personalCenter/notices",
        name: "notices",
        component: () => import("../pages/personalCenter/notices/index.vue"),
      },
      {
        path: "/personalCenter/collect",
        name: "collect",
        component: () => import("../pages/personalCenter/collect/index.vue"),
      },
      {
        path: "/personalCenter/QA",
        name: "QA",
        component: () => import("../pages/personalCenter/QA/index.vue"),
        children: [
          {
            path: "/personalCenter/QA/details",
            name: "QAdetails",
            component: () => import("../pages/personalCenter/QA/details.vue"),
          }
        ]
      },
      {
        path: "/personalCenter/updateTel",
        name: "updateTel",
        component: () => import("../pages/personalCenter/updateTel/index.vue"),
      },
      {
        path: "/personalCenter/vipOrder",
        name: "vipOrder",
        component: () => import("../pages/personalCenter/vipOrder/index.vue"),
      },
      {
        path: "/personalCenter/messageReturn",
        name: "messageReturn",
        component: () => import("../pages/personalCenter/messageReturn/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  // 路由（页面跳转时），滚动条显示在顶部
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
  routes,
});

export default router;
