

<template>
  <div class="mySubcontract">
    <div class="page_title">
      我的分包
    </div>
    <div class="tabs_box">
      <Tabs :tabData="statusList" :gapSize="38" @handleClick="changeStatus" />
    </div>
    <div class="list_box">
      <template v-if="list_data.lists.length">
        <SubcontractList :lists="list_data.lists" :numberRow="1" itemType="op" @changeData="getData"></SubcontractList>
      </template>
      <el-empty :image-size="200" v-else></el-empty>

    </div>
    <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
  </div>
</template>
  
<script>
import Tabs from '@/components/Tabs.vue'
import mixin from '../../../mixin'
import SubcontractList from '../../../components/subcontract/SubcontractList.vue'
export default {
  mixins: [mixin],
  components: {
    Tabs,
    SubcontractList
  },
  data () {
    return {
      curStatus: 'accept',
      statusList: [
        {
          text: '已发布',
          value: 'accept',
          active: true
        },
        {
          text: '已过期',
          value: 'out_date',
          active: false
        },
        {
          text: '已下架',
          value: 'down',
          active: false
        },
        {
          text: '审核中',
          value: 'wait',
          active: false
        },
        {
          text: '未通过',
          value: 'refused',
          active: false
        }
      ]
    }
  },
  methods: {
    changeStatus (index) {
      this.statusList.forEach((item) => {
        item.active = false
      })
      this.statusList[index].active = true
      this.curStatus = this.statusList[index].value;
      this.getData();
    },
    getData () {
      this.getList('getMyPackage', this.page, { status: this.curStatus });
    },
    jumpLink (id) {
      this.$router.push({
        path: '/check-introduce',
        query: {
          id
        }
      })
    },
    pauseOrOpenJob (id, type) {
      if (type == 'break') {
        this.$confirm('此操作暂停招工,是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.breakJob({ id }).then(res => {
            if (res.code == 1) {
              this.$message({
                type: 'success',
                message: '已暂停招工'
              });
              this.getData();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else {
        this.$confirm('此操作将开启招工,是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$api.unBreakJob({ id }).then(res => {
            if (res.code == 1) {
              this.$message({
                type: 'success',
                message: '已开启招工!'
              });
              this.getData();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    },

  },
  created () {
    this.getData();
  },
  mounted () {
  },
  watch: {

  },
}
</script>
  
<style lang='scss'>
.mySubcontract {
  padding: 26px;
  .page_title {
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    margin-bottom: 20px;
  }
  .tabs_box {
    margin-bottom: 30px;
  }
  .list_box {
    min-height: 500px;
    .jobs_item {
      box-shadow: 0px 0px 5px 0px #e4e9ff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #e5e5e5;
      margin-bottom: 20px;
      padding: 17px 21px;
      .jobs_item_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;
        .jobs_name {
          font-size: 18px;
          font-weight: 500;
          color: #333333;
        }
        .jobs_pirce {
          font-size: 13px;
          font-weight: 500;
          color: #ff5100;
          span {
            font-size: 22px;
          }
        }
      }
      .jobs_describe {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        margin-bottom: 12px;
      }
      .jobs_item_bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .jobs_info_left {
          .jobs_row_box {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            .jobs_info_title {
              color: #999999;
              margin-right: 2px;
            }
            .jobs_info_text {
              color: #333333;
            }
          }
        }
        .right_btn_box {
          display: flex;
          align-items: center;
          gap: 0 10px;
          .small_blue_btn {
            background: #3f63ff;
            border-radius: 5px 5px 5px 5px;
            padding: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #ffffff;
          }
          .small_fn_blue_btn {
            background: #ebeefc;
            border-radius: 5px 5px 5px 5px;
            padding: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #3f63ff;
          }
        }
      }
    }
  }
}
</style>