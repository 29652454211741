<template>
  <div class="topFilterBox area_box">
    <slot name="top"></slot>
    <div class="topFilterBoxTitle">
      筛选条件
    </div>
    <slot name="first"></slot>
    <div class="filter_box">
      <div class="filter_title">
        地区:
      </div>
      <div class="filter_content">
        <div :class="['filter_c_item',cur_province.label == pItem.label ? 'filter_active_item' : '']" v-for="(pItem,pIndex) in cityArr" :key="pIndex"
          @click="chooseProvince(pIndex)">
          <el-popover placement="bottom" width="491" trigger="click" @hide="hideChildren" @show="showChildren">
            <div class="filter_children_box">
              <div :class="['children_item', cur_citys_name.indexOf(city.label) != -1 ? 'active_children_item' : '']"
                v-for="(city, index) in cur_province.children" :key="index" @click.stop="chooseCity(city)">
                {{ city.label }}
              </div>
            </div>
            <button class="filter_c_item_name" slot="reference">
              {{ pItem.label }}
              <div class="choose_num" v-if="curChooseNum(pItem)">{{ curChooseNum(pItem) }}</div>
            </button>
          </el-popover>
        </div>
      </div>
    </div>
    <slot name="three"></slot>
  </div>
</template>

<script>
import City from '../common/js/city.json'
export default {
  props: {

  },
  data () {
    return {
      cityArr: City,
      cur_province: {},//记录当前选择的省份
      cur_provinceIndex: -1,//记录当前选择的省份下标
      cur_citys: [],//当前选择的城市
      cur_provinces_name: [],//当前选择的省份 用于显示激活css
      cur_citys_name: []//当前选择的城市 用于显示激活css
    }
  },
  methods: {
    chooseProvince (index) {
      this.cur_provinceIndex = index;
    },
    showChildren () {
      setTimeout(() => {
        this.cur_province = this.cityArr[this.cur_provinceIndex];
      }, 10)
    },
    hideChildren () {
      this.cur_province = {};
    },
    chooseCity (city) {
      console.log(city, '当前城市');

      if (this.cur_citys_name.indexOf(city.label) == -1) {
        if (this.cur_citys_name.length >= 3) {
          this.$message.error('最多只能选择三个城市');
          return;
        }
        this.cur_citys_name.push(city.label);
        this.cur_citys.push({
          ...city,
          province_name: this.cur_province.label,
          province_code: this.cur_province.code
        });
      } else {
        this.cur_citys_name = this.cur_citys_name.filter(item => item != city.label);
        this.cur_citys = this.cur_citys.filter(item => item.label != city.label);
      }
      let cityCode = this.cur_citys.map(item => {
        return item.value;
      })
      this.$emit('change', cityCode);
    },
    curChooseNum (province) {
      let findProvince = this.cur_citys.find(item => item.province_name === province.label);
      if (findProvince) {
        let num = 0;
        this.cur_citys.forEach(item => {
          if (item.province_name == province.label) {
            num++;
          }
        });
        return num;
      } else {
        return 0;
      }
    }
  }
}
</script>

<style lang="scss">
.topFilterBox {
  margin-top: 18px;
  .topFilterBoxTitle {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 28px;
  }
}
.filter_children_box {
  // position: absolute;
  // bottom: -154px;
  // left: 0;
  width: 491px;
  box-sizing: border-box;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
  .children_item {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
  }
  .active_children_item {
    color: #3f63ff;
  }
}
</style>