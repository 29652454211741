
<template>
  <div class="publishSubcontract">
    <div class="page_title">
      发布分包
    </div>
    <div class="page_content">
      <div class="form_box">
        <el-form :model="sumitObj" :rules="rules" ref="sumitObj" label-width="100px" class="demo-sumitObj">
          <el-form-item label="标题:" prop="name">
            <el-input v-model="sumitObj.name" type="text" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="承包队形:" prop="type">
            <el-radio-group v-model="sumitObj.type">
              <el-radio label="2">班组</el-radio>
              <el-radio label="3">施工队</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="班组类型:" prop="category_ids" v-if="sumitObj.type==2">
            <el-cascader v-model="sumitObj.category_ids" collapse-tags :props="props" :options="$store.state.categoryConfig.team_type"
              placeholder="请选择班组类型">
            </el-cascader>
          </el-form-item>
          <el-form-item label="施工队类型:" prop="category_ids" v-else>
            <el-cascader v-model="sumitObj.category_ids" collapse-tags :props="props" :options="$store.state.categoryConfig.build_type"
              placeholder="请选择施工队类型">
            </el-cascader>
          </el-form-item>
          <el-form-item label="截止时间:" prop="end_date">
            <el-date-picker :picker-options="pickerOptions" value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="sumitObj.end_date" type="date"
              placeholder="请选择招标截止日期">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="联系人:" prop="contact">
            <el-input v-model="sumitObj.contact" placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系电话:" prop="contact_phone">
            <el-input v-model="sumitObj.contact_phone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="期望地区:" prop="area_code">
            <RegionSelect :value="sumitObj.area_code" @changeRegion="changeRegion"></RegionSelect>
          </el-form-item>
          <el-form-item label="详细地址:" prop="address">
            <el-input v-model="sumitObj.address" placeholder="请输入详细地址"></el-input>
          </el-form-item>
          <div class="page_area_title">
            <span class="must_point">*</span> 工程描述
          </div>
          <div class="text_area_box">
            <el-form-item prop="desc">
              <el-input type="textarea" v-model="sumitObj.desc" placeholder="可简单介绍一下工程信息"></el-input>
            </el-form-item>
          </div>
          <div class="page_area_title">
            <span class="must_point">*</span> 上传附件
          </div>
          <div class="upload_img_box">
            <div class="upload_img_tags_text">
              如图纸照片，现场照片等
            </div>
            <el-form-item prop="images">
              <uploadImageList ref="uploadImageList" @success="uploadSuccess">
              </uploadImageList>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <button class="blue_btn" @click="submitForm('sumitObj')">发布分包</button>
    </div>
  </div>
</template>
  
<script>
import RegionSelect from '../../../components/regionSelect.vue'
import uploadImageList from '@/components/uploadImage/uploadImageList.vue';
import { validatePass } from '@/utils';

export default {
  components: {
    RegionSelect,
    uploadImageList
  },
  data () {
    return {
      pageAuth: 'publish_package',
      props: { value: 'id', label: 'name', children: 'child' },
      pageAuth: 'publish_package',
      sumitObj: {
        name: '',
        type: '2',
        end_date: '',
        contact: '',
        contact_phone: '',
        category_ids: [],
        area: '',
        area_code: [],
        address: '',
        desc: '',
        images: []
      },
      rules: {
        name: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请输入选择类型', trigger: 'change' },
        ],
        end_date: [
          { required: true, message: '请输入截止时间', trigger: 'change' },
        ],
        contact: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        contact_phone: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' },
        ],
        category_ids: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ],
        area_code: [
          { required: true, message: '请选择项目地区', trigger: 'blur' }
        ],
        images: [
          { required: true, message: '请上传附件', trigger: 'change' }
        ],
        address: [
          { required: false, message: '请输入详细地址', trigger: 'blur' },
        ],
        desc: [
          { required: true, message: '请输入招工描述', trigger: 'blur' },
        ],
      },
      pickerOptions: {
        disabledDate (date) {
          //这里设置今天以前的不可选
          return date.getTime() < Date.now() - 60 * 60 * 1000;
        },
      },
      curJobTags: [],
    }
  },
  methods: {
    changejob (val) {
      if (this.sumitObj.job_tag_ids.indexOf(val) == -1) {
        this.sumitObj.job_tag_ids.push(val);
      } else {
        this.sumitObj.job_tag_ids = this.sumitObj.job_tag_ids.filter(item => item != val);
      }
    },
    uploadSuccess (val) {
      this.$set(this.sumitObj, 'images', val);
    },
    changeRegion (val) {
      console.log(val, 'sasa');
      this.sumitObj.area_code = val.area_code;
      this.sumitObj.area = val.area;
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            ...this.sumitObj
          }
          params.category_ids = params.category_ids[1];
          params.images += '';
          params.area_code = `${this.sumitObj.area_code[0]}/${this.sumitObj.area_code[1]}`;
          this.$api.publishPackage(params).then(res => {
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.sumitObj = {
                name: '',
                type: '2',
                end_date: '',
                contact: '',
                contact_phone: '',
                category_ids: [],
                area: '',
                area_code: [],
                address: '',
                desc: '',
                images: []
              }
              this.$refs.uploadImageList.clearImageList();
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    }
  },
  mounted () {
    this.checkMemberDealAuth(this.pageAuth);
  },
  watch: {

  },
}
</script>
  
<style lang='scss' scoped>
.publishSubcontract {
  background-color: #fff;
  padding: 26px;
  .page_title {
    padding-bottom: 12px;
    border-bottom: 1px solid #e5e5e5;
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    margin-bottom: 30px;
  }
  .job_type {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .job_type_item {
      width: 76px;
      height: 31px;
      background: #f6f6f6;
      border-radius: 3px 3px 3px 3px;
      opacity: 1;
      border: 1px solid #f6f6f6;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      box-sizing: border-box;
      margin-right: 7px;
      cursor: pointer;
    }
    .active_type_item {
      background: #f3f5ff;
      opacity: 1;
      border: 1px solid #3f63ff;
      color: #3f63ff;
    }
  }
  .tips {
    margin: 0 0 26px;
  }
  .page_content {
    .page_area_title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 13px;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .form_box {
      margin-bottom: 23px;
       .el-form-item {
        label {
          width: 110px !important;
        }
        .el-form-item__content {
          margin-left: 110px !important;
        }
      }
       .el-select {
        width: 100%;
      }
      .upload_img_box {
        margin-bottom: 50px;
         .el-form-item .el-form-item__content {
          margin-left: 0 !important;
        }
        .upload_img_tags_text {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          margin-bottom: 10px;
        }
      }
      .text_area_box {
         .el-form-item .el-form-item__content {
          margin-left: 0 !important;
        }
         .el-textarea textarea {
          height: 106px;
        }
      }
    }
  }
}
</style>