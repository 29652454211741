<template>
  <div class="productList">
    <List :numberRow="numberRow" :gutter="gutter">
      <div class="package_item" v-for="(item, index) in lists" :key="index" slot="Item">
        <productItem :itemData="item" :itemType="itemType" @changeData="changeData"></productItem>
      </div>
    </List>
  </div>
</template>

<script>
import productItem from './productItem.vue';
import List from '../List.vue'
export default {
  name: 'productList',
  components: { productItem, List },
  props: {
    numberRow: {
      type: Number,
      default: 2
    },
    gutter: {
      type: Number,
      default: 20
    },
    lists: {
      type: Array,
      default: () => ([])
    },
    //list列表 查看   op 我的分包，有底部按钮，可操作
    itemType: {
      type: String,
      default: 'list'
    },
  },
  data () {
    return {};
  },
  methods: {
    changeData () {
      this.$emit('changeData');
    }
  }
};
</script>

<style lang="scss" scoped>
.productList {
}
</style>
