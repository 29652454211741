<template>
  <div class="getBids">
    <div class="w">
      <topFilterBox @change="chooseCity">
        <div class="filter_box" slot="first">
          <div class="filter_title">
            中标类型:
          </div>
          <div class="filter_content">
            <div :class="['filter_c_item',filter.category_ids.indexOf(pItem.id ) != -1? 'filter_active_item' : '']"
              v-for="(pItem,pIndex) in $store.state.categoryConfig.win_notice" :key="pIndex" @click="chooseType(pItem,'category_ids')">
              <button class="filter_c_item_name">
                {{ pItem.name }}
              </button>
            </div>
          </div>
        </div>
        <div class="filter_box" slot="first">
          <div class="filter_title">
            项目金额:
          </div>
          <div class="filter_content">
            <div :class="['filter_c_item',(filter.start_money==pItem.start_money&&filter.end_money==pItem.end_money)? 'filter_active_item' : '']"
              v-for="(pItem,pIndex) in promoney_list" :key="pIndex" @click="chooseType(pItem,'money')">
              <button class="filter_c_item_name">
                {{ pItem.name }}
              </button>
            </div>
          </div>
        </div>
        <div class="filter_box" slot="first">
          <div class="filter_title">
            附件:
          </div>
          <div class="filter_content">
            <div :class="['filter_c_item',(filter.is_have_file===pItem.value)? 'filter_active_item' : '']" v-for="(pItem,pIndex) in file_type_list"
              :key="pIndex" @click="chooseType(pItem,'is_have_file')">
              <button class="filter_c_item_name">
                {{ pItem.name }}
              </button>
            </div>
          </div>
        </div>
        <div class="filter_box" slot="three">
          <div class="filter_title">
            时间:
          </div>
          <div class="filter_content">
            <div
              :class="['filter_c_item',(filter.start_time==pItem.start_time&&filter.end_time==pItem.end_time&&!diyTimeRange.length)? 'filter_active_item' : '']"
              v-for="(pItem,pIndex) in time_type" :key="pIndex" @click="chooseType(pItem,'start_time')">
              <button class="filter_c_item_name">
                {{ pItem.name }}
              </button>
            </div>
          </div>
        </div>
        <div class="filter_box" slot="three">
          <div class="filter_title">
            自定义时间:
          </div>
          <div class="filter_content">
            <el-date-picker v-model="diyTimeRange" @change="changeDiyTime" type="daterange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </div>
      </topFilterBox>
      <div class="list_box">
        <div class="lists_total_num_box">
          共找到<span>{{ list_data.total }}</span>条信息
        </div>
        <BidsList bidsType="win" :lists="list_data.lists" :numberRow="2" v-if="list_data.lists.length"></BidsList>
        <el-empty :image-size="200" v-else></el-empty>
      </div>
      <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
    </div>
  </div>
</template>

<script>
import BidsList from '../../components/Bids/BidsList.vue';
import topFilterBox from '../../components/topFilterBox.vue';
import Pager from '../../components/pager/Pager.vue'
import mixin from '../../mixin/index'
import { mapState } from 'vuex';
export default {
  mixins: [mixin],
  components: {
    BidsList,
    topFilterBox,
    Pager
  },
  data () {
    return {
      bids_type: [
        { name: '招标预告', value: 0 },
        { name: '信息变更', value: 1 },
        { name: '答疑公告', value: 2 },
        { name: '重新招标', value: 3 },
        { name: '废标公告', value: 4 },
      ],
      time_type: [
        { name: '今日', value: 0, start_time: '', end_time: '' },
        { name: '近7天', value: 7, start_time: '', end_time: '' },
        { name: '近30天', value: 30, start_time: '', end_time: '' },
        { name: '近3个月', value: 90, start_time: '', end_time: '' },
        { name: '近6个月', value: 180, start_time: '', end_time: '' },
        { name: '近9个月', value: 270, start_time: '', end_time: '' },
      ],
      promoney_list: [
        { name: '0-100万', start_money: 0, end_money: 1000000 },
        { name: '100-500万', start_money: 1000000, end_money: 5000000 },
        { name: '500-1000万', start_money: 5000000, end_money: 10000000 },
        { name: '1000-5000万', start_money: 10000000, end_money: 50000000 },
        { name: '5000-8000万', start_money: 50000000, end_money: 80000000 },
        { name: '8000万以上', start_money: 80000000, end_money: 1000000000 },
      ],
      file_type_list: [
        { name: '有附件', value: 1 },
        { name: '无附件', value: 0 },
      ],
      diyTimeRange: [],//自定义时间
      filter: {
        keyword: '',
        city_codes: [],
        category_ids: [],
        start_money: '',
        end_money: '',
        start_time: '',
        end_time: '',
        is_have_file: null,
      }
    }
  },
  created () {
    this.initDateArr();
    this.getData();
  },
  computed: {
    ...mapState({
      keyword: state => state.keyword,
    }),
  },
  methods: {
    chooseCity (val) {
      this.filter.city_codes = val;
      this.getData();
    },
    changeDiyTime (val) {
      if (val) {
        this.filter.start_time = val[0] + ' 00:00:00';
        this.filter.end_time = val[1] + ' 23:59:59';
      } else {
        this.filter.start_time = '';
        this.filter.end_time = '';
      }
      this.getData();

    },
    chooseType (val, type) {
      if (type == 'category_ids') {
        if (this.filter.category_ids.indexOf(val.id) == -1) {
          if (this.filter.category_ids.length >= 3) {
            this.$message.error('最多只能选择3个类型');
            return;
          }
          this.filter.category_ids.push(val.id);
        } else {
          this.filter.category_ids = this.filter.category_ids.filter(item => item != val.id);
        }
      }
      if (type == 'start_time') {
        if (this.filter.end_time == val.end_time) {
          this.filter.start_time = "";
          this.filter.end_time = "";
        } else {
          this.filter.start_time = val.start_time;
          this.filter.end_time = val.end_time;
        }
        this.diyTimeRange = [];
      }
      if (type == 'money') {
        if (this.filter.start_money == val.start_money && this.filter.end_money == val.end_money) {
          this.filter.start_money = "";
          this.filter.end_money = "";
        } else {
          this.filter.start_money = val.start_money;
          this.filter.end_money = val.end_money;
        }
      }

      if (type == 'is_have_file') {
        if (this.filter.is_have_file === val.value) {
          this.filter.is_have_file = null;
        } else {
          this.filter.is_have_file = val.value;
        }
      }
      console.log(this.filter, val, 'sfafa');
      this.getData();
    },
    initDateArr () {
      this.time_type.forEach(item => {
        item.start_time = this.getDateWithOffset(item.value) + ' 00:00:00';
        item.end_time = this.getDateWithOffset(0) + ' 23:59:59';
      })
    },
    getData () {
      let params = {
        ...this.filter
      }
      params.city_codes += '';
      params.category_ids += '';
      if (this.keyword.length) {
        params.keyword = this.keyword;
      }
      for (let key in params) {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      }
      this.getList('getWinList', this.page, params);
    }
  },

}
</script>

<style lang="scss">
.getBids {
  min-height: 100vh;
  padding-bottom: 92px;
  .w {
    width: 1200px;
    margin: 0 auto;
  }
  .list_box {
    .lists_total_num_box {
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      margin: 24px 0 29px;
      span {
        color: #3f63ff;
      }
    }
  }
}
</style>