<template>
    <div class="Image">
        <img :src="imgSrc" alt="" />
    </div>
</template>
<script>
    export default {
        name: 'IImage',
        props: {
            src: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                qiniu_url: 'https://qnimg.zgshjsw.cn/'
            };
        },
        computed: {
            imgSrc() {
                if (this.src.indexOf('http') != -1) {
                    return this.src;
                } else {
                    return this.qiniu_url + this.src;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .Image {
    }
</style>
