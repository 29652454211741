<template>
  <div class="epList">
    <List :numberRow="numberRow" :gutter="gutter">
      <div class="list_ep_item" v-for="(item, index) in lists" :key="index" slot="Item">
        <epItem :itemType="itemType" :itemData="item"></epItem>
      </div>
    </List>
  </div>
</template>

<script>
import epItem from './epItem.vue';
import List from '../List.vue'
export default {
  name: 'epList',
  components: { epItem, List },
  props: {
    numberRow: {
      type: Number,
      default: 2
    },
    gutter: {
      type: Number,
      default: 20
    },
    lists: {
      type: Array,
      default: () => ([])
    },
    itemType: {
      type: String,
      default: 'list'
    }
  },
  data () {
    return {};
  },

};
</script>

<style lang="scss">
.epList {
}
</style>
