<template>
  <div class="newsList">
    <div class="w">
      <div class="list_box" v-if="list_data.lists.length">
        <div class="news_item_box" v-for="(item,index) in list_data.lists" :key="index" @click="goDetails(item.id)">
          <div class="news_time_box">
            <div class="news_time_month">
              {{ item.month_text}}
            </div>
            <div class="news_time_day">
              {{ item.day_text}}
            </div>
          </div>
          <div class="news_content_box">
            <div class="news_title_box">
              {{ item.title}}
            </div>
            <div class="news_desc_box">
              {{ item.desc }}
            </div>
          </div>
          <div class="news_img_box">
            <img :src="item.image" alt="">
          </div>
        </div>
      </div>
      <el-empty :image-size="200" v-else></el-empty>
      <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
    </div>

  </div>
</template>

<script>
import Pager from '../../components/pager/Pager.vue'
import mixin from '../../mixin/index'

export default {
  mixins: [mixin],
  components: {
    Pager
  },
  created () {
    this.getData();
  },
  methods: {
    getData () {
      this.getList('getNewsList', this.page).then(res => {
        this.list_data.lists = this.list_data.lists.map(item => {
          let time = item.create_time.split(' ');
          let timeArr = time[0].split('-');
          item.month_text = `${timeArr[0]}-${timeArr[1]}`;
          item.day_text = `${timeArr[2]}`;
          return item;
        });
      });
    },
    goDetails (id) {
      this.$router.push({
        path: '/home-news-details',
        query: {
          id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.newsList {
  padding: 45px 0;
  .list_box {
    .news_item_box {
      background: #ffffff;
      box-shadow: 0px 0px 5px 0px #e4e9ff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      display: flex;
      padding: 18px 20px 20px 0;
      margin-bottom: 20px;
      transition: transform 0.3s ease;
      cursor: pointer;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 0px 12px 0px #c5c8d5;
      }
      .news_time_box {
        width: 118px;
        box-sizing: border-box;
        flex: none;
        text-align: center;
        border-right: 1px dashed #9fa1a5;
        padding-top: 20px;
        .news_time_month {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          margin-bottom: 22px;
        }
        .news_time_day {
          font-size: 36px;
          font-weight: 700;
          color: #9e9e9e;
        }
      }
      .news_content_box {
        padding: 24px 24px 10px 19px;
        flex: 1;
        .news_title_box {
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 6px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .news_desc_box {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .news_img_box {
        width: 197px;
        height: 120px;
        flex: none;
      }
    }
  }
}
</style>