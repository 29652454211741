import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

const getCategory = () => {
  let categoryArr = localStorage.getItem('commonCateGoryConfig');
  if (categoryArr) {
    categoryArr = JSON.parse(categoryArr);
  } else {
    categoryArr = {
      business: [],
      industry: [],
      invitation_notice: [],
      job: [],
      job_tag: [],
      scale: [],
      win_notice: [],
      search_tabs_show: false
    };
  }
  return categoryArr
}
const getUserInfo = () => {
  let userInfo = localStorage.getItem('userInfo');
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo;
  } else {
    return {
      personal_auth: '', //是否完成个人认证，0=未认证，1=已认证，2=审核中，3-审核拒绝
      company_auth: '', //是否完成企业认证，0=未认证，1=已认证，2=审核中，3-审核拒绝
      company_vip: '',//是否是企业vip，0=否，1=是，2=已过期
      vip: {
        vip_level: -1
      }
    }
  }
}
export default new Vuex.Store({
  state: {
    userInfo: getUserInfo(),
    qaDetailsShow: false,
    // 控制页面是否正在显示loading
    isShowLoading: false,
    vipShow: false,
    //控制显示企业是否认证
    approveShow: false,
    categoryConfig: getCategory(),
    keyword: '',
    type_text: {
      0: '未认证',
      1: '已认证',
      2: '审核中',
      3: '审核拒绝'
    }
  },
  getters: {

  },
  mutations: {
    //设置用户信息
    SET_USER_INFO (state, userInfo) {
      state.userInfo = userInfo;
    },
    //设置是否显示loading
    SET_SHOWLOADING (state, data) {
      state.isShowLoading = data;
    },
    //设置常见问题页面是否显示详情页
    SET_QA_DETAILS_SHOW (state, data) {
      state.qaDetailsShow = data;
    },
    //设置搜索关键字
    SET_KEYWORD (state, data) {
      state.keyword = data.keyword;
      state.search_tabs_show = data.show;
    },
    //设置企业认证弹窗
    SET_APPROVE_SHOW (state, data) {
      state.approveShow = data;
    },
    //设置vip弹窗
    SET_VIP_SHOW (state, data) {
      state.vipShow = data;
    }
  },
  actions: {

  }
})
