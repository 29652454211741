<template>
  <div :class="['rect',bg_color]">
    {{ text }}
  </div>
</template>
  
<script>
export default {
  props: ['text'],
  computed: {
    bg_color () {
      let bg_color = '';
      switch (this.text) {
        case '未认证':
          break;
        case '已认证':
          bg_color = 'success_bg_color'
          break;
        case '审核中':
          break;
        case '审核拒绝':
          bg_color = 'error_bg_color'
          break;
      }
      console.log('gaga', bg_color);

      return bg_color;
    }
  }
}
</script>
  
<style lang="scss" scoped>
.rect {
  font-size: 10px;
  font-weight: 500;
  color: #ffffff;
  line-height: 18px;
  display: flex;
  align-items: center;
  padding: 4px 3px;
  height: 18px;
  background: #3f63ff;
  border-radius: 4px 4px 4px 4px;
  text-align: center;
}
</style>