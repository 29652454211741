<template>
  <div class="List" :style="{ gridTemplateColumns: gridColumns,gap:intervalPx }">
    <slot name="Item"></slot>
  </div>
</template>

<script>
export default {
  name: 'List',
  props: {
    numberRow: {
      type: Number,
      default: 2
    },
    gutter: {
      type: Number,
      default: 20
    }
  },
  data () {
    return {};
  },
  computed: {
    gridColumns () {
      return `repeat(${this.numberRow}, 1fr)`;
    },
    intervalPx () {
      return `${this.gutter}px`;

    }
  },
};
</script>

<style lang="scss">
.List {
  display: grid;
}
</style>
