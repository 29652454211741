
<template>
  <div class="myPurchase">
    <div class="tabs_box">
      <Tabs size="big" :showBorder="true" :tabData="bidsBar" @handleClick="changeHandle" />
    </div>
    <div class="page_content">
      <div class="status_tabs" v-if="curBidsType=='getMyPurchase'">
        <Tabs :tabData="statusList" :gapSize="60" @handleClick="changeStatus" />
      </div>
      <div class="list_box">
        <purchaseList :lists="list_data.lists" v-if="list_data.lists.length" @changeData="getData" :itemType="itemType" :numberRow="1"></purchaseList>
        <el-empty :image-size="200" v-else></el-empty>
      </div>
      <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
    </div>
  </div>
</template>
  
<script>
import Tabs from '@/components/Tabs.vue'
import purchaseList from '../../../components/purchase/purchaseList.vue'
import mixin from '../../../mixin'
export default {
  mixins: [mixin],
  components: {
    Tabs,
    purchaseList
  },
  data () {
    return {
      curBidsType: 'getMyPurchase',
      curStatus: 'accept',
      bidsBar: [
        {
          id: 1,
          text: '我的招采',
          value: 'getMyPurchase',
          active: true
        },
        {
          id: 2,
          text: '我的报价',
          value: 'getMyPriceList',
          active: false
        },
      ],
      statusList: [
        {
          text: '已发布',
          value: 'accept',
          active: true
        },
        {
          text: '已过期',
          value: 'out_date',
          active: false
        },
        {
          text: '已下架',
          value: 'down',
          active: false
        },
        {
          text: '审核中',
          value: 'wait',
          active: false
        },
        {
          text: '未通过',
          value: 'refused',
          active: false
        }
      ],
    }
  },
  created () {
    this.getData();
  },
  computed: {
    itemType () {
      if (this.curBidsType == 'getMyPurchase') {
        return 'op';
      } else {
        return 'myPrice';
      }
    }
  },
  methods: {
    changeHandle (index) {
      this.bidsBar.forEach((item) => {
        item.active = false
      })
      this.bidsBar[index].active = true
      this.curBidsType = this.bidsBar[index].value;
      this.getData();
    },
    changeStatus (index) {
      this.statusList.forEach((item) => {
        item.active = false
      })
      this.statusList[index].active = true
      this.curStatus = this.statusList[index].value;
      this.getData();
    },
    getData () {
      if (this.curBidsType == 'getMyPurchase') {
        this.getList('getMyPurchase', this.page, { status: this.curStatus });
      } else {
        this.getList('getMyPriceList', this.page);
      }
    }
  },
  mounted () {
  },
  watch: {

  },
}
</script>
  
<style lang='scss' scoped>
.myPurchase {
  padding: 26px;
  .tabs_box {
    margin-bottom: 38px;
  }
  .page_content {
    .status_tabs {
      margin-bottom: 28px;
    }
    .list_box {
      min-height: 500px;
    }
  }
}
</style>