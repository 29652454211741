<template>
  <div class="recruitJob">
    <div class="w">
      <!-- <introduceComList></introduceComList> -->

      <topFilterBox @change="chooseCity">
        <div class="tabs_box" slot="top">
          <Tabs :fnSz="20" :gapSize="64" :showBorder="true" :tabData="workBars" @handleClick="changeHandle" />
        </div>
        <div class="filter_box" slot="first" v-if="curWorkType=='getJobList'">
          <div class="filter_title">
            工作标签:
          </div>
          <div class="filter_content">
            <div :class="['filter_c_item',filter.job_tag_id==pItem.id? 'filter_active_item' : '']"
              v-for="(pItem,pIndex) in $store.state.categoryConfig.job_tag" :key="pIndex" @click="chooseType(pItem,'job_tag_id')">
              <button class="filter_c_item_name">
                {{ pItem.name }}
              </button>
            </div>
          </div>
        </div>
        <div class="filter_box" slot="first" v-if="curWorkType=='getResumeList'">
          <div class="filter_title">
            简历类型:
          </div>
          <div class="filter_content">
            <div :class="['filter_c_item',filter.type==pItem.id? 'filter_active_item' : '']" v-for="(pItem,pIndex) in type_list" :key="pIndex"
              @click="chooseType(pItem,'type')">
              <button class="filter_c_item_name">
                {{ pItem.name }}
              </button>
            </div>
          </div>
        </div>
        <div class="filter_box" slot="three">
          <div class="filter_title">
            岗位名称:
          </div>
          <div class="filter_content">
            <div :class="['filter_c_item',cur_father.name == pItem.name ? 'filter_active_item' : '']"
              v-for="(pItem,pIndex) in $store.state.categoryConfig.job" :key="pIndex" @click="choosefather(pIndex)">
              <el-popover placement="bottom" width="400" trigger="click" @show="showChildren" @hide="hideChildren">
                <div class="filter_children_box">
                  <div :class="['children_item', cur_sons_name.indexOf(son.name) != -1 ? 'active_children_item' : '']"
                    v-for="(son, index) in cur_father.child" :key="index" @click.stop="chooseJob(son)">
                    {{ son.name }}
                  </div>
                </div>
                <button class="filter_c_item_name" slot="reference">
                  {{ pItem.name }}
                  <div class="choose_num" v-if="curChooseNum(pItem)">{{ curChooseNum(pItem) }}</div>
                </button>
              </el-popover>
            </div>
          </div>
        </div>

      </topFilterBox>
      <div class="list_box">
        <div class="lists_total_num_box">
          共找到<span>{{ list_data.total }}</span>条信息
        </div>
        <template v-if="curWorkType=='getJobList'">
          <jobList itemType="list" :lists="list_data.lists" :numberRow="2" v-if="list_data.lists.length"></jobList>
          <el-empty :image-size="200" v-else></el-empty>
        </template>
        <template v-if="curWorkType=='getResumeList'">
          <introduceComList itemType="list" :lists="list_data.lists" :numberRow="2" v-if="list_data.lists.length"></introduceComList>
          <el-empty :image-size="200" v-else></el-empty>
        </template>
      </div>
      <Pager @changePage="changePage" @getList="getData" :page_size="page.page_size" :total="list_data.total"></Pager>
    </div>
  </div>
</template>

<script>
import jobList from '../../components/job/jobList.vue'
import topFilterBox from '../../components/topFilterBox.vue';
import introduceComList from '../../components/introduce/introduceComList.vue';
import Tabs from '../../components/Tabs.vue'
import Pager from '../../components/pager/Pager.vue'
import mixin from '../../mixin/index'
import { mapState } from 'vuex';
export default {
  mixins: [mixin],
  components: {
    jobList,
    introduceComList,
    topFilterBox,
    Pager,
    Tabs
  },
  data () {
    return {
      workBars: [{
        text: '我要找活',
        value: 'getJobList',
        active: true
      },
      {
        text: '我要招人',
        value: 'getResumeList',
        active: false
      },],
      curWorkType: 'getJobList',
      cur_father: {},//记录当前选择的省份
      cur_fatherIndex: -1,//记录当前选择的省份下标
      cur_sons: [],//当前选择的城市
      cur_fathers_name: [],//当前选择的省份 用于显示激活css
      cur_sons_name: [],//当前选择的城市 用于显示激活css
      filter: {
        keyword: '',
        city_codes: [],
        category_ids: [],
        job_tag_id: '',
        type: ''
      },
      type_list: [
        {
          name: '优质个人',
          id: 1
        },
        {
          name: '优质班组',
          id: 2
        },
        {
          name: '优质施工队',
          id: 3
        }
      ],
    }
  },
  created () {
    this.getData();
  },
  computed: {
    ...mapState({
      keyword: state => state.keyword,
    }),
    jobsTypeList () {
      let arr = [];
      this.$store.state.categoryConfig.job.forEach(item => {
        if (item.child) {
          item.child.forEach(elem => {
            arr.push({
              name: `${item.name}/${elem.name}`,
              id: elem.id
            })
          })
        }
      })
      return arr;
    }
  },
  methods: {
    chooseCity (val) {
      this.filter.city_codes = val;
      this.getData();
    },
    choosefather (index) {
      this.cur_fatherIndex = index;
    },
    showChildren () {
      setTimeout(() => {
        this.cur_father = this.$store.state.categoryConfig.job[this.cur_fatherIndex];
        console.log(this.cur_father, 'gagag')
      }, 10)
    },
    hideChildren () {
      this.cur_father = {};
    },
    curChooseNum (father) {
      let findfather = this.cur_sons.find(item => item.father_name === father.name);
      if (findfather) {
        let num = 0;
        this.cur_sons.forEach(item => {
          if (item.father_name == father.name) {
            num++;
          }
        });
        return num;
      } else {
        return 0;
      }
    },
    chooseJob (son) {
      console.log(son, '当前城市');
      if (this.cur_sons_name.indexOf(son.name) == -1) {
        if (this.cur_sons_name.length >= 3) {
          this.$message.error('最多只能选择三个城市');
          return;
        }
        this.cur_sons_name.push(son.name);
        this.cur_sons.push({
          ...son,
          father_name: this.cur_father.name,
          father_code: this.cur_father.code
        });
      } else {
        this.cur_sons_name = this.cur_sons_name.filter(item => item != son.name);
        this.cur_sons = this.cur_sons.filter(item => item.name != son.name);
      }
      let sonCode = this.cur_sons.map(item => {
        return item.id;
      })
      console.log(sonCode, 'gggggggg')
      this.filter.category_ids = sonCode;
      this.getData();
    },
    changeHandle (index) {
      this.workBars.forEach((item) => {
        item.active = false
      })
      this.workBars[index].active = true
      this.curWorkType = this.workBars[index].value;
      this.filter.job_tag_id = '';
      this.filter.type = '';
      this.getData();
    },
    chooseType (val, type) {
      if (type == 'job_tag_id') {
        if (this.filter.job_tag_id == val.id) {
          this.filter.job_tag_id = ''
        } else {
          this.filter.job_tag_id = val.id;
        }
      }
      if (type == 'type') {
        if (this.filter.type == val.id) {
          this.filter.type = ''
        } else {
          this.filter.type = val.id;
        }
      }
      this.getData();
    },

    getData () {
      let params = {
        ...this.filter
      }
      params.city_codes += '';
      params.category_ids += '';
      if (this.keyword.length) {
        params.keyword = this.keyword;
      }
      for (let key in params) {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      }
      this.getList(this.curWorkType, this.page, params);
    }
  },
  watch: {
    keyword (val) {
      this.filter.keyword = val;
      this.getData();
    }
  }
}
</script>

<style lang="scss">
.recruitJob {
  min-height: 100vh;
  padding-bottom: 92px;
  .w {
    width: 1200px;
    margin: 0 auto;
  }
  .tabs_box {
    margin-bottom: 27px;
  }
  .list_box {
    .lists_total_num_box {
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      margin: 24px 0 29px;
      span {
        color: #3f63ff;
      }
    }
  }
}
</style>