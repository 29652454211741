<template>
    <div class="basicInfo">
        <div class="info">个人资料</div>
        <div class="avatar">
            <uploadImage stats="avatar" @success="uploadSuccess">
                <div slot="uploadContent">
                    <img :src="userInfo.avatar" alt="" v-if="userInfo.avatar" />
                    <img src="@/assets/img/icon/img_basicInfo_avatar.png" alt="" v-else />
                </div>
            </uploadImage>
            <span class="avatar_update">点击修改头像</span>
        </div>
        <div class="nickname">
            <label for="nickname">昵称</label>
            <br />
            <input type="text" v-model="userInfo.nickname" id="nickname" />
        </div>
        <div class="sex">
            <div class="sex_title">性别</div>
            <el-select v-model="userInfo.sex" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
        </div>
        <button @click="saveUserInfo">保存</button>
    </div>
</template>

<script>
    import uploadImage from '../../../components/uploadImage/uploadImage.vue';

    export default {
        components: {
            uploadImage
        },
        data() {
            return {
                squareUrl: require('@/assets/img/icon/img_basicInfo_avatar.png'),
                options: [
                    {
                        value: '男',
                        label: '男'
                    },
                    {
                        value: '女',
                        label: '女'
                    }
                ],
                userInfo: {
                    avatar: ''
                }
            };
        },
        mounted() {
            this.getUserInfo();
        },
        methods: {
            uploadSuccess(val) {
                this.userInfo[val.status] = val.img;
            },
            getUserInfo() {
                let userInfo = localStorage.getItem('userInfo');
                if (userInfo) {
                    this.userInfo = JSON.parse(userInfo);
                }
            },
            saveUserInfo() {
                const { avatar, sex, nickname } = this.userInfo;
                let params = {
                    avatar,
                    sex,
                    nickname
                };
                this.$api.editMemberInfo(params).then((res) => {
                    if (res.code == 1) {
                        this.$message.success('修改成功!');
                    }
                    this.$api.getMemberInfo().then((rex) => {
                        localStorage.setItem('userInfo', JSON.stringify(rex.data));
                        location.reload();
                    });
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    * {
        box-sizing: border-box;
    }

    .basicInfo {
        border-radius: 8px 8px 8px 8px;
        padding: 0 17px 234px;
        background: #fff;

        .info {
            padding: 21px 0 16px;
            font-size: 22px;
            font-weight: 500;
            color: #2f2f2f;
            line-height: 26px;
            border-bottom: 1px solid #e5e5e5;
        }

        .avatar {
            padding-top: 73px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }
            .avatar_update {
                margin: 5px 0 14px;
                font-size: 13px;
                font-weight: 400;
                color: #3f63ff;
                line-height: 16px;
                cursor: pointer;
            }
        }

        .nickname {
            label {
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                line-height: 13px;
            }

            input {
                width: 100%;
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                line-height: 19px;
                border-bottom: 1px solid #e5e5e5;
                padding: 15px 0 13px;
            }
        }

        .sex {
            margin-top: 19px;

            .sex_title {
                font-size: 14px;
                font-weight: 500;
                color: #999999;
                line-height: 13px;
                margin-bottom: 20px;
            }
            .el-select {
                width: 100%;
                border-bottom: 1px solid #e5e5e5;
            }
        }
        .el-input__inner {
            border: none !important;
            padding-left: 0 !important;
            padding-bottom: 13px !important;
            color: #333333 !important;
            font-size: 18px;
        }

        button {
            display: block;
            width: 240px;
            height: 50px;
            background: #3f63ff;
            color: #fff;
            border-radius: 5px 5px 5px 5px;
            text-align: center;
            align-items: 50px;
            font-size: 15px;
            font-weight: 500;
            margin: 60px auto 0;
            cursor: pointer;
        }
    }
</style>
